import { EnvironmentConfiguration } from 'app/config/evironment-config';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { RequestHelper } from 'app/core/http/request-helper';
import { HttpClient } from '@angular/common/http';
var ConfigService = /** @class */ (function () {
    function ConfigService(http, environmentConfig, requestHelper, routeWrapper) {
        this.http = http;
        this.environmentConfig = environmentConfig;
        this.requestHelper = requestHelper;
        this.routeWrapper = routeWrapper;
        this.baseApi = {};
        this.appConfig = {};
        this.baseApi = this.environmentConfig.get('baseApiUrl');
    }
    ConfigService.prototype.loadConfig = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var headers = _this.requestHelper.initHeaders(null);
            var umbraco = _this.environmentConfig.get('umbraco');
            var url = _this.environmentConfig.get('getSettingsBypassingOSB') ? umbraco.settingsBypassingOSB : umbraco.settings;
            _this.http.get(_this.baseApi + url, { headers: headers })
                .catch(function (error) { return _this.requestHelper.errorHandler(error); }).subscribe(function (config) {
                _this.appConfig = config;
                if (_this.appConfig['systemUndermaintenance']) {
                    _this.routeWrapper.toErrorPage({ translationKey: 'Errors.General.SystemUnderMaintenance' }, true);
                    resolve(false);
                    return;
                }
                resolve(true);
            }, function (error) {
                _this.routeWrapper.toErrorPage();
                resolve(false);
            });
        });
    };
    ConfigService.prototype.get = function (key) {
        if (this.appConfig === undefined) {
            return null;
        }
        return this.appConfig[key];
    };
    return ConfigService;
}());
export { ConfigService };
