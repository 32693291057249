import {Subject} from 'rxjs';


export class MessageModalService {

    modalStateChange = new Subject<any>();

    callModal(message: string, title: string, options: any = {}) {

        this.modalStateChange.next({message: message, title: title, options: options});
    }

    hideModal() {
        this.modalStateChange.next(null);
    }
}
