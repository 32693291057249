import {StorageDataReader} from '../../shared/services/storage-data-reader.service';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PermissionsMap} from 'app/core/permission/app.permissions-map.config';
import {RouterWrapper} from 'app/core/routing/router.wrapper';

@Injectable()
export class RoutePermissionService {
    allowedMenuItems = new Subject<any>();
    userData: any;

    constructor(private permissionsMap: PermissionsMap,
                private routerWrapper: RouterWrapper,
                private localStor: StorageDataReader) {

    }

    allowedForCustomer(routeName: string) {

        this.userData = this.localStor.getZimAuthData();

        const data = this.userData || {contact: {services: []}};

        data.contact.services = data.contact.services || [];

        const allowed = data.contact.services
            .find(x => x.serviceName === this.permissionsMap.customerRoutePermissionMap[routeName] && x.isEnabled) ? true : false;

        return allowed;
    }

    checkAllowedMenuItems() {

        this.userData = this.localStor.getZimAuthData();

        const data: any = this.userData || {contact: {services: []}};

        data.contact.services = data.contact.services || [];

        const permissions = {};

        for (let i = 0; i < data.contact.services.length; i++) {
            permissions[data.contact.services[i].serviceName] = data.contact.services[i].isEnabled;
        }

        this.allowedMenuItems.next(permissions);
    }

    getPermissions() {
        this.userData = this.localStor.getZimAuthData();

        const data: any = this.userData || {contact: {services: []}};

        data.contact.services = data.contact.services || [];

        const permissions = {};

        for (let i = 0; i < data.contact.services.length; i++) {
            permissions[data.contact.services[i].serviceName] = data.contact.services[i].isEnabled;
        }

        return permissions;
    }
}
