import {Observable} from 'rxjs/Rx';
import {Injectable} from '@angular/core';

import {HttpClientService} from '../../../core/http/http-client.service';
import {EnvironmentConfiguration} from '../../../config/evironment-config';

@Injectable()
export class NavbarService {
    baseApi: any;
    apiEndPoint: any;
    commonEndPoint: any;

    constructor(private http: HttpClientService,
                private environmentConfig: EnvironmentConfiguration,
                private httpClient: HttpClientService) {
        this.apiEndPoint = environmentConfig.get('externalEndPoints').topNavigationEndPoint;

        this.baseApi = environmentConfig.get('baseApiUrl');
        this.commonEndPoint = environmentConfig.get('commonEndPoints');
    }

    getSubPagesData(): Observable<any> {
        return this.http.get(this.apiEndPoint);
    }

    sendLanguage(request: any): Observable<any> {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.setMyLanguage, request);
    }
}
