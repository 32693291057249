import { throwError as observableThrowError } from 'rxjs';
import 'rxjs/Rx';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { CookieService } from 'ngx-cookie';
import { HttpHeaders } from '@angular/common/http';
var RequestHelper = /** @class */ (function () {
    function RequestHelper(cookiesService, routerWrapper) {
        this.cookiesService = cookiesService;
        this.routerWrapper = routerWrapper;
    }
    RequestHelper.prototype.errorHandler = function (error) {
        switch (error.status) {
            case 401:
                this.routerWrapper.toLogin();
        }
        return observableThrowError(error);
    };
    RequestHelper.prototype.setHeaders = function (headers) {
        if (!headers) {
            headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        }
        else {
            if (!headers.has('Content-Type')) {
                headers.append('Content-Type', 'application/json');
            }
        }
        return headers;
    };
    RequestHelper.prototype.setAuthToken = function (headers) {
        var access_token = this.cookiesService.get('zim_access_token');
        headers = headers.append('Authorization', 'Bearer ' + access_token);
        return headers;
    };
    RequestHelper.prototype.initHeaders = function (headers, anonymous) {
        if (anonymous === void 0) { anonymous = false; }
        headers = this.setHeaders(headers);
        if (!anonymous) {
            headers = this.setAuthToken(headers);
        }
        return headers;
    };
    return RequestHelper;
}());
export { RequestHelper };
