var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MessageModalService } from 'app/shared/components/message-modal/message-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ElementRef, QueryList } from '@angular/core';
import { ConfigService } from 'app/config/config.sevice';
import { ScrollingService } from 'app/shared/services/scrolling.service';
import { CookieService } from 'ngx-cookie';
import { HttpClientService } from '../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../config/evironment-config';
import { tap } from 'rxjs/operators';
import { MobileService } from '../services/mobile.service';
var ScreenGeneral = /** @class */ (function () {
    function ScreenGeneral(messageModalService, translateService, configService, scrollingService, cookieService, httpClient, mobileService, environmentConfiguration) {
        this.messageModalService = messageModalService;
        this.translateService = translateService;
        this.configService = configService;
        this.scrollingService = scrollingService;
        this.cookieService = cookieService;
        this.httpClient = httpClient;
        this.mobileService = mobileService;
        this.environmentConfiguration = environmentConfiguration;
        this.selectAllId = 77777;
        this.baseApi = environmentConfiguration.get('baseApiUrl');
        this.commonEndPoints = environmentConfiguration.get('commonEndPoints');
    }
    // build pickList
    ScreenGeneral.prototype.buildFilter = function (response, concatProperties, needDefaultOption) {
        var _this = this;
        if (needDefaultOption === void 0) { needDefaultOption = ['SOURCE_STATE', 'CUSTOMER']; }
        var filterData = {};
        if (+response.esbResponse.returnCode < 0) {
            this.messageModalService.callModal(this.translateService.instant('Errors.General.GeneralError'), this.translateService.instant('Errors.General.GeneralTitle'));
        }
        if (response.pickListItems) {
            var _loop_1 = function (filter) {
                // for (let i = 0; i < filter.pickListValues.length; i++) {
                //     if (!filter.pickListValues[i].code && filter.pickListValues[i].description) {
                //         filter.pickListValues[i].code = filter.pickListValues[i].description;
                //     } else if (filter.pickListValues[i].code && !filter.pickListValues[i].description) {
                //         filter.pickListValues[i].description = filter.pickListValues[i].code;
                //     }
                // }
                // filter.pickListValues = filter.pickListValues.filter(item => item.code && item.description);
                var items = filter.pickListValues.map(function (data) {
                    var _a;
                    return _a = {
                            id: data.code
                        },
                        _a[filter.fieldName === 'CUSTOMER' || filter.fieldName === 'SOURCE_STATE' ? 'name' : 'text'] = _this.createFilterText(data.description, data.code, filter.fieldName, concatProperties),
                        _a.parentId = _this.selectAllId,
                        _a;
                });
                if (filter.fieldName === 'CUSTOMER' || filter.fieldName === 'SOURCE_STATE') {
                    if (items.length > 1) {
                        items.unshift({ id: this_1.selectAllId, name: this_1.translateService.instant('Labels.SelectAll') });
                    }
                }
                if (filter.fieldName === 'VOYAGE' || filter.fieldName === 'IMP_VOYAGE') {
                    items = items.sort(function (a, b) {
                        return a.text - b.text;
                    });
                }
                if (needDefaultOption.indexOf(filter.fieldName) === -1) {
                    items.unshift({ id: '', text: '' });
                }
                filterData[filter.fieldName] = items;
            };
            var this_1 = this;
            for (var _i = 0, _a = response.pickListItems; _i < _a.length; _i++) {
                var filter = _a[_i];
                _loop_1(filter);
            }
        }
        return filterData;
    };
    // callGeneralError
    ScreenGeneral.prototype.screenGeneralError = function () {
        this.messageModalService.callModal(this.translateService.instant('Errors.General.GeneralError'), this.translateService.instant('Errors.General.GeneralTitle'));
    };
    // concate value and description for some fields
    ScreenGeneral.prototype.createFilterText = function (description, value, fieldName, concatProperties) {
        return concatProperties.indexOf(fieldName) > -1 ? description + " - " + value : description || value;
    };
    // get bls by type from print dropdown
    ScreenGeneral.prototype.getBLsByType = function (tableData, selectedType) {
        var bls = [];
        for (var i = 0; i < tableData.items.length; i++) {
            if (selectedType === 'Original') {
                if (tableData.items[i].type === 'ORIGINAL') {
                    bls.push(tableData.items[i].blNo);
                }
            }
            else if (selectedType === 'Sea Waybill') {
                if (tableData.items[i].type === 'WAYBILL') {
                    bls.push(tableData.items[i].blNo);
                }
            }
        }
        return bls;
    };
    ScreenGeneral.prototype.getSelectedItems = function (tableData, key) {
        if (key === void 0) { key = 'blNo'; }
        var bls = [];
        for (var i = 0; i < tableData.items.length; i++) {
            if (tableData.items[i].selected) {
                bls.push(tableData.items[i][key]);
            }
        }
        return bls;
    };
    ;
    // selectRow(row, event, tableData:any, printLimitKey:string) {
    //     const selectedAmount = tableData.items.filter(function (s) { return s.selected; }).length;
    //     const printLimit = this.configService.get(printLimitKey) ? this.configService.get(printLimitKey) : 5;
    //     if (selectedAmount >= printLimit && event.target.checked) {
    //       row.selected = false;
    //       event.target.checked = false;
    //       this.messageModalService.callModal(this.translateService.instant('Errors.General.AllowedNumber',
    //                                { number: printLimit }), this.translateService.instant('Errors.General.GeneralTitle'));
    //       return;
    //     } else {
    //       if (row.status == 'READY_TO_PRINT' && row.virtContactAction == 'PRINT') {
    //         row.selected = !(row.selected)
    //         this.buildDataForPrintPicker();
    //       }
    //     }
    // }
    ScreenGeneral.prototype.removeFilterEmptyFields = function (filter) {
        var _this = this;
        var copyFilter = __assign({}, filter);
        Object.keys(copyFilter).forEach(function (key) {
            if (copyFilter.statusList) {
                copyFilter.statusList = copyFilter.statusList.slice();
                for (var i = 0; i < copyFilter.statusList.length; i++) {
                    if (copyFilter.statusList[i] === _this.selectAllId) {
                        copyFilter.statusList.splice(i, 1);
                    }
                }
            }
            if (copyFilter.status) {
                copyFilter.status = copyFilter.status.slice();
                for (var i = 0; i < copyFilter.status.length; i++) {
                    if (copyFilter.status[i] === _this.selectAllId) {
                        copyFilter.status.splice(i, 1);
                    }
                }
            }
            if (copyFilter.customerList) {
                copyFilter.customerList = copyFilter.customerList.slice();
                for (var i = 0; i < copyFilter.customerList.length; i++) {
                    if (copyFilter.customerList[i] === _this.selectAllId) {
                        copyFilter.customerList.splice(i, 1);
                    }
                }
            }
            if (copyFilter.customer) {
                copyFilter.customer = copyFilter.customer.slice();
                for (var i = 0; i < copyFilter.customer.length; i++) {
                    if (copyFilter.customer[i] === _this.selectAllId) {
                        copyFilter.customer.splice(i, 1);
                    }
                }
            }
            if (!copyFilter[key]) {
                delete copyFilter[key];
            }
            else if (copyFilter[key].hasOwnProperty('fromDate') && copyFilter[key].hasOwnProperty('toDate')) {
                if (!copyFilter[key].fromDate && !copyFilter[key].toDate) {
                    delete copyFilter[key];
                }
                else {
                    if (copyFilter[key].fromDate && !copyFilter[key].toDate) {
                        copyFilter[key].toDate = _this.AddYear(copyFilter[key].fromDate, 1);
                    }
                    else if (!copyFilter[key].fromDate && copyFilter[key].toDate) {
                        copyFilter[key].fromDate = _this.AddYear(copyFilter[key].toDate, -1);
                    }
                    copyFilter[key].fromDate = copyFilter[key].fromDate.toFilterString();
                    copyFilter[key].toDate = copyFilter[key].toDate.toFilterString();
                }
            }
        });
        return copyFilter;
    };
    ScreenGeneral.prototype.scrollUp = function () {
        this.scrollingService.scrollUp('search-table-holder', 500, 50, true);
    };
    ScreenGeneral.prototype.checkSelectedRows = function (tableData, selectedRows, key) {
        var _loop_2 = function (i) {
            var index = tableData.items.findIndex(function (item) { return item[key] === selectedRows[i][key]; });
            if (index > -1) {
                tableData.items[index]['selected'] = true;
            }
        };
        for (var i = 0; i < selectedRows.length; i++) {
            _loop_2(i);
        }
    };
    ;
    ScreenGeneral.prototype.selectedColumnsToObject = function (settings, tableAlias, tableColumns) {
        var columns = {};
        settings.selectedColumns.forEach(function (val) {
            columns[val] = val;
        });
        var selectedColumnsObject = columns;
        var unselectedColumns = this.array_diff(tableColumns, settings.selectedColumns);
        localStorage.setItem(tableAlias, JSON.stringify(unselectedColumns));
        return selectedColumnsObject;
    };
    ;
    ScreenGeneral.prototype.array_diff = function (second, first) {
        return second.filter(function (i) {
            return first.indexOf(i) < 0;
        });
    };
    ScreenGeneral.prototype.translatePlaceholders = function (directives) {
        var _this = this;
        setTimeout(function () {
            var _loop_3 = function (i) {
                var element = directives['_results'][i].nativeElement;
                var self_1 = jQuery(element.children[0]).data('select2');
                self_1.selection.placeholder = { id: '', text: _this.translateService.instant(element.getAttribute('placeholder')) };
                self_1.dataAdapter.current(function (data) {
                    self_1.trigger('selection:update', {
                        data: data
                    });
                });
            };
            for (var i = 0; i < directives.length; i++) {
                _loop_3(i);
            }
        }, 100);
    };
    ScreenGeneral.prototype.translateColumns = function (settings) {
        settings.tableColumns = this.translateTableColumns(settings.tableColumns);
        return settings;
    };
    ScreenGeneral.prototype.translateTableColumns = function (columns) {
        var _this = this;
        var resultColumns = new Array();
        columns.forEach(function (element) {
            resultColumns.push({
                'trKey': element.trKey,
                'lblKey': element.lblKey,
                'name': _this.translateService.instant(element.trKey),
                'id': element.id,
                'parentId': element.parentId,
                'sortKey': element.sortKey
            });
        });
        return resultColumns;
    };
    ScreenGeneral.prototype.detectRtl = function () {
        var lang = this.cookieService.get('language');
        var rtlLanguages = ['ae', 'ar', 'arc', 'bcc', 'bqi', 'ckb', 'dv',
            'fa', 'glk', 'he', 'ku', 'mzn', 'nqo', 'pnb', 'ps', 'sd', 'ug', 'ur', 'yi'];
        return rtlLanguages.indexOf(lang) !== -1;
    };
    ScreenGeneral.prototype.setFocusOnElement = function (element) {
        element.nativeElement.focus();
    };
    ScreenGeneral.prototype.onMSelectClose = function () {
        setTimeout(function () {
            $('ss-multiselect-dropdown .dropdown button').attr('aria-expanded', 'false');
        }, 100);
    };
    ScreenGeneral.prototype.onMSelectOpen = function () {
        setTimeout(function () {
            $('li.dropdown-item').each(function (index, elm) {
                $($(elm).find('a')).attr('tabindex', 0);
                $($(elm).find('a')).attr('role', 'button');
            });
            $('ss-multiselect-dropdown .dropdown button').attr('aria-expanded', 'true');
        }, 100);
        this.addAriaPressedMSelect();
    };
    ScreenGeneral.prototype.addAriaMultiselect = function () {
        setTimeout(function () {
            $('ss-multiselect-dropdown .dropdown button').attr('aria-haspopup', 'true');
            $('ss-multiselect-dropdown .dropdown button').attr('aria-expanded', 'false');
        }, 100);
    };
    ScreenGeneral.prototype.onMSelectModelChange = function (event, classState) {
        if (classState) {
            classState.state = (!(!event || !event.length));
        }
        this.addAriaPressedMSelect();
    };
    ScreenGeneral.prototype.addAriaPressedMSelect = function () {
        setTimeout(function () {
            $('li.dropdown-item').each(function (index, elm) {
                if ($(elm).children().children().children().length > 0) {
                    $($(elm).find('a')).attr('aria-pressed', 'true');
                }
                else {
                    $($(elm).find('a')).attr('aria-pressed', 'false');
                }
            });
        }, 100);
    };
    ScreenGeneral.prototype.getStatusPickList = function (dsaId, serviceName) {
        return this.httpClient.get(this.baseApi + this.commonEndPoints.statusPickList + ("?dsaID=" + dsaId + "&serviceName=" + serviceName))
            .pipe(tap(function (obj) { return obj.pickListValues.sort(function (a, b) { return Number(a.sequence) - Number(b.sequence); }); }));
    };
    ScreenGeneral.prototype.getPickListWithReplacedStatus = function (pickList, sortPickList) {
        var status = pickList.pickListItems.find(function (x) { return x.fieldName === sortPickList.fieldName; });
        if (status) {
            status.pickListValues = sortPickList.pickListValues;
        }
        else {
            pickList.push(sortPickList);
        }
        return pickList;
    };
    ScreenGeneral.prototype.scrollTableToBeginning = function () {
        var isRtl = document.body.classList.contains('rtl');
        if (this.mobileService.isMobile()) {
            $('html').animate({
                scrollTop: 0
            });
        }
        $('.search-table-wrap').animate({
            scrollLeft: isRtl ? $('.search-table-wrap').width() : '0px'
        });
    };
    ScreenGeneral.prototype.AddYear = function (date, amount) {
        return new Date(new Date(date).setFullYear(date.getFullYear() + amount));
    };
    return ScreenGeneral;
}());
export { ScreenGeneral };
