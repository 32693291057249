import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import localeKo from '@angular/common/locales/ko';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHant from '@angular/common/locales/zh-Hant';
registerLocaleData(localeHe);
registerLocaleData(localeKo);
registerLocaleData(localeZhHans);
registerLocaleData(localeZhHant);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
