import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {AuthService} from 'app/auth/auth.service';
import {CookieService} from 'ngx-cookie';

import {JwtHelper} from 'app/shared/helpers/JWTParser';
import {MessageModalService} from 'app/shared/components/message-modal/message-modal.service';
import {EnvironmentConfiguration} from 'app/config/evironment-config';
import {ConfigService} from 'app/config/config.sevice';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {ErrorHandlerService} from '../../shared/services/error-handler.service';

@Component({
    selector: 'app-details',
    templateUrl: './auth.component.html',
    encapsulation: ViewEncapsulation.None
})

export class AuthComponent implements OnInit {
    jwtHelper: JwtHelper;
    authLayer = {
        setAuthToken: (tokenDataId: string) => {
            this.cookieService.put('zim_access_token', tokenDataId, {secure: true});

            forkJoin(
                this.authService.getAuthData(),
                this.configService.loadConfig()
            ).subscribe(([data]) => {
                this.languages = this.configService.get('languages');

                if (data.contact == null || data.dsaData == null) {
                    this.routerWrapper.toLogin();
                    return;
                }
                this.authService.setAuthData(data);
                this.setLanguage(data.contact.language);
                const redirectUrl = this.cookieService.get('zim_redirect_url');
                if (redirectUrl) {
                    this.cookieService.remove('zim_redirect_url');
                    this.routerWrapper.toPage(redirectUrl);
                } else {
                    this.routerWrapper.toHome();
                }

            }, error => {
                if (error.status === 401) {
                    this.routerWrapper.toLogin();
                    return;
                }
                this.routerWrapper.toErrorPage();
            });

        },
        checkErrors: () => {
            if (window.location.href.includes('error_description=AADB2C90118')) {
                window.location.href = this.environmentConfig.get('signIn').forgotPasswordLink;
                return;
            } else if (window.location.href.includes('error_description=AADB2C90091')) {
                window.location.href = this.environmentConfig.get('signIn').loginLink;
                return;
            } else if (window.location.href.includes('error_description=AADB2C90075')) {
                this.routerWrapper.toErrorPage();
                return;
            }
        }
    };
    languages: any;

    constructor(private authService: AuthService,
                private route: ActivatedRoute,
                private cookieService: CookieService,
                private routerWrapper: RouterWrapper,
                private messageModal: MessageModalService,
                private configService: ConfigService,
                private translate: TranslateService,
                private environmentConfig: EnvironmentConfiguration,
                private errorHandlerServise: ErrorHandlerService
    ) {
        this.jwtHelper = new JwtHelper();
        this.languages = this.configService.get('languages');
    }

    ngOnInit(): void {
        const token = this.getParameterByName('#id_token', window.location.href);
        const error = this.getParameterByName('#error', window.location.href);

        if (!token) {
            window.location.href = this.environmentConfig.get('signIn').loginLink;
        }

        this.authLayer.checkErrors();

        if (error) {
            return;
        }

        const validDsa = this.authService.checkTokenDsa(token);

        if (validDsa) {
            this.authLayer.setAuthToken(token);
        } else {
            this.errorHandlerServise.handleInactiveUserError();
        }
    }

    getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[a-z]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    private setLanguage(langIso: string) {
        if (this.languages) {
            if (this.languages.filter(x => x.iso === langIso).length !== 1) {
                const defLang = this.languages.filter(x => x.isDefault === true);
                langIso = defLang.length !== 1 ? 'en' : defLang[0].iso
            }
            this.cookieService.put('language', langIso);
        }
    }
}
