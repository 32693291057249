import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie';


@Injectable()
export class AccessibilityService {

    constructor(private cookieService: CookieService) {
    }

    enableAccessibilityMode(): void {
        const  element = document.getElementsByTagName('body')[0];
        element.classList.add('accessibility');
    }

    disableAccessibilityMode(): void {
        const  element = document.getElementsByTagName('body')[0];
        if (element.classList.contains('accessibility')) {
            element.classList.remove('accessibility');
        }

    }

    updateLangOnHtml(lang: string): void {
        if (!lang) {
            lang = this.cookieService.get('language');
        }
        const html = document.getElementsByTagName('html')[0];
        html.lang = lang;
    }
}
