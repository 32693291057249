import { HttpClientService } from '../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../config/evironment-config';
import { Observable } from 'rxjs';
var MySettingsService = /** @class */ (function () {
    function MySettingsService(httpClient, environmentConfig) {
        this.httpClient = httpClient;
        this.environmentConfig = environmentConfig;
        this.baseApi = environmentConfig.get('baseApiUrl');
        this.commonEndPoint = environmentConfig.get('commonEndPoints');
        this.countryPhoneCodesData = require('assets/data/country-phone-codes.json');
    }
    MySettingsService.prototype.getMyUserProfile = function (request) {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.getMyUserProfile, request);
    };
    MySettingsService.prototype.setMyUserProfile = function (request) {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.setMyUserProfile, request);
    };
    MySettingsService.prototype.getCountryPhoneCodes = function () {
        var _this = this;
        return new Observable(function (observer) {
            observer.next(_this.countryPhoneCodesData);
            observer.complete();
        });
    };
    MySettingsService.prototype.getTimeZonePicklist = function (request) {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.getTimeZonePicklist, request);
    };
    return MySettingsService;
}());
export { MySettingsService };
