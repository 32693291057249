import { Subject } from 'rxjs';
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.loaderStateChanged = new Subject();
        this.coverAllLoaderStateChanged = new Subject();
        this.componentsLoaderStateChanged = new Subject();
    }
    LoaderService.prototype.showSpinner = function () {
        this.loaderStateChanged.next(true);
    };
    LoaderService.prototype.hideSpinner = function () {
        this.loaderStateChanged.next(false);
    };
    LoaderService.prototype.showCoverAllSpinner = function () {
        this.coverAllLoaderStateChanged.next(true);
    };
    LoaderService.prototype.hideCoverAllSpinner = function () {
        this.coverAllLoaderStateChanged.next(false);
    };
    LoaderService.prototype.showComponentsSpinner = function () {
        this.componentsLoaderStateChanged.next(true);
    };
    LoaderService.prototype.hideComponentsSpinner = function () {
        this.componentsLoaderStateChanged.next(false);
    };
    return LoaderService;
}());
export { LoaderService };
