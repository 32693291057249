import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FollowOrderPipe} from '../../pipes/follow-order.pipe';
import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable()
export class PdfPopupManager {
    popupStateChange = new Subject<any>();
    public followOrder = new FollowOrderPipe();
    tempOrder = ['PRINT_BL_ORIG',
        'PRINT_BL_ATTACHED_ORIG',
        'PRINT_BL_COPY_FRT',
        'PRINT_BL_ATTACHED_COPY_FRT',
        'PRINT_BL_COPY_UNFRT',
        'PRINT_BL_ATTACHED_COPY_UNFRT'];

    constructor(public translateService: TranslateService) {
    }


    callPopup(files: any[], options: string = '#toolbar=1&navpanes=1&scrollbar=1&zoom=100') {
        files = this.setOrder(files);
        const items = files.map((data): Object => {
            return {
                id: data.docName,
                text: this.translateService.instant(`PdfNames.${data.docType}`),
            };
        });
        this.popupStateChange.next({files: items, options: options});
    }

    private setOrder(files: Array<any>): Array<any> {
        return this.followOrder.transform(files, this.tempOrder, 'docType');
    }
}
