import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'postfix'})
export class PostfixPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            return value + '/';
        }
    }
}
