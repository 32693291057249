import { Subject } from 'rxjs';
var MessageModalService = /** @class */ (function () {
    function MessageModalService() {
        this.modalStateChange = new Subject();
    }
    MessageModalService.prototype.callModal = function (message, title, options) {
        if (options === void 0) { options = {}; }
        this.modalStateChange.next({ message: message, title: title, options: options });
    };
    MessageModalService.prototype.hideModal = function () {
        this.modalStateChange.next(null);
    };
    return MessageModalService;
}());
export { MessageModalService };
