import { ConfigService } from '../../config/config.sevice';
var DateRangeService = /** @class */ (function () {
    function DateRangeService(configService) {
        this.configService = configService;
    }
    DateRangeService.prototype.validateFn = function (control) {
        control.controls.fromDate.setErrors(null);
        control.controls.toDate.setErrors(null);
        var dateRange = { fromDate: control.value.fromDate, toDate: control.value.toDate };
        this.validate(dateRange);
        if (dateRange.fromDateError) {
            control.controls.fromDate.setErrors({ 'validationError': dateRange.fromDateError });
        }
        if (dateRange.toDateError) {
            control.controls.toDate.setErrors({ 'validationError': dateRange.toDateError });
        }
        return null;
    };
    DateRangeService.prototype.validate = function (dateRange) {
        dateRange.fromDateError = null;
        dateRange.toDateError = null;
        if (dateRange.fromDate && dateRange.toDate) {
            if (dateRange.fromDate > dateRange.toDate) {
                dateRange.fromDateError = 'Errors.Date.FromDateIsGreaterThenTo';
            }
        }
        return !dateRange.fromDateError && !dateRange.toDateError;
    };
    DateRangeService.prototype.init = function (container) {
        return {
            toDate: this.initDate(container, 'to'),
            fromDate: this.initDate(container, 'from'),
            toDateError: null,
            fromDateError: null,
        };
    };
    DateRangeService.prototype.initDate = function (container, direction) {
        var date = new Date();
        switch (container) {
            case 'empty':
                switch (direction) {
                    case 'to':
                        date = null;
                        break;
                    case 'from':
                        date = null;
                        break;
                }
                break;
            case 'export':
                switch (direction) {
                    case 'to':
                        if (!this.configService.get('exportFilterToDays') || this.configService.get('exportFilterToDays') === 0) {
                            date = null;
                        }
                        else {
                            date.setDate(new Date().getDate() + this.configService.get('exportFilterToDays'));
                        }
                        break;
                    case 'from':
                        if (!this.configService.get('exportFilterFromDays') || this.configService.get('exportFilterFromDays') === 0) {
                            date = null;
                        }
                        else {
                            date.setDate(new Date().getDate() - this.configService.get('exportFilterFromDays'));
                        }
                        break;
                }
                break;
            case 'import':
                switch (direction) {
                    case 'to':
                        if (!this.configService.get('importFilterToDays') || this.configService.get('importFilterToDays') === 0) {
                            date = null;
                        }
                        else {
                            date.setDate(new Date().getDate() + this.configService.get('importFilterToDays'));
                        }
                        break;
                    case 'from':
                        if (!this.configService.get('importFilterFromDays') || this.configService.get('importFilterFromDays') === 0) {
                            date = null;
                        }
                        else {
                            date.setDate(new Date().getDate() - this.configService.get('importFilterFromDays'));
                        }
                        break;
                }
                break;
            case 'finance':
                switch (direction) {
                    case 'to':
                        if (!this.configService.get('financeFilterToDays') || this.configService.get('financeFilterToDays') === 0) {
                            date = null;
                        }
                        else {
                            date.setDate(new Date().getDate() + this.configService.get('financeFilterToDays'));
                        }
                        break;
                    case 'from':
                        if (!this.configService.get('financeFilterFromDays') || this.configService.get('financeFilterFromDays') === 0) {
                            date = null;
                        }
                        else {
                            date.setDate(new Date().getDate() - this.configService.get('financeFilterFromDays'));
                        }
                        break;
                }
                break;
        }
        return date;
    };
    return DateRangeService;
}());
export { DateRangeService };
