import { ActivatedRoute } from '@angular/router';
var ViewSettings = /** @class */ (function () {
    function ViewSettings(route) {
        this.route = route;
        this.RouteViewSettingsnMap = {
            'print': 'printBl',
            'zim-monitor': 'monitorData',
            'imp-my-shipments': 'myShipmentsImport',
            'exp-my-shipments': 'myShipmentsExport',
            'arrival-notice': 'arrivalNotice',
            'delivery-order': 'deliveryOrder',
            'booking-confirmation': 'bookingConfirmation',
            'bl-copy': 'copyBl',
            'seaway-bill': 'seawayBill',
            'finance': 'copyInvoice',
            'user-management': 'userManagement'
        };
        this.currentRouteName = this.route.snapshot.data.name;
    }
    ViewSettings.prototype.printBl = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 1 },
                { trKey: 'Labels.Status', trKeyM: 'Labels.Status', lblKey: 'status', name: 'Status', sortKey: 'SOURCE_STATE', id: 'SOURCE_STATE', parentId: 1 },
                { trKey: 'Labels.Type', trKeyM: 'Labels.Type', lblKey: 'type', name: 'Type', sortKey: 'BILL_TYPE', id: 'BILL_TYPE', parentId: 1 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', lblKey: 'BLNo', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 1 },
                {
                    trKey: 'Labels.SailingDate',
                    trKeyM: 'Labels.SailingDate',
                    lblKey: 'sailingDate',
                    name: 'Sailing Date',
                    sortKey: 'SAILING_DATE',
                    id: 'SAILING_DATE',
                    parentId: 1
                },
                {
                    trKey: 'Labels.VesselVoyageDirection',
                    trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 1
                },
                { trKey: 'Labels.POL', trKeyM: 'Labels.POLShort', lblKey: 'POL', name: 'Port of Loading', sortKey: 'POL', id: 'POL', parentId: 1 },
                { trKey: 'Labels.POD', trKeyM: 'Labels.PODShort', lblKey: 'POD', name: 'Port of Discharge', sortKey: 'POD', id: 'POD', parentId: 1 },
                {
                    trKey: 'Labels.Forwarder',
                    trKeyM: 'Labels.Forwarder',
                    lblKey: 'forwarder',
                    name: 'Forwarder',
                    sortKey: 'FORWARDER',
                    id: 'FORWARDER',
                    parentId: 1
                },
                { trKey: 'Labels.Shipper', trKeyM: 'Labels.Shipper', lblKey: 'shipper', name: 'Shipper', sortKey: 'SHIPPER', id: 'SHIPPER', parentId: 1 }
            ],
            selectedColumns: [1, 'SOURCE_STATE', 'BILL_TYPE', 'BL_NO', 'SAILING_DATE', 'VVL', 'POL', 'POD', 'FORWARDER', 'SHIPPER'],
            store: JSON.parse(localStorage.getItem('printBl_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.copyBl = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 2 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', lblKey: 'BLNo', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 2 },
                { trKey: 'Labels.BLType', trKeyM: 'Labels.BLType', name: 'B/L Type', lblKey: 'BLTYPE', sortKey: 'BILL_TYPE', id: 'BL_TYPE', parentId: 2 },
                {
                    trKey: 'Labels.SailingDate',
                    trKeyM: 'Labels.SailingDate',
                    lblKey: 'sailingDate',
                    name: 'Sailing Date',
                    sortKey: 'SAILING_DATE',
                    id: 'SAILING_DATE',
                    parentId: 2
                },
                {
                    trKey: 'Labels.VesselVoyageDirection',
                    trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 2
                },
                {
                    trKey: 'Labels.PortOfLoading',
                    trKeyM: 'Labels.PortOfLoading',
                    lblKey: 'POL',
                    name: 'Port of Loading',
                    sortKey: 'POL',
                    id: 'POL',
                    parentId: 2
                },
                {
                    trKey: 'Labels.PortOfDischarge',
                    trKeyM: 'Labels.PortOfDischarge',
                    lblKey: 'POD',
                    name: 'Port of Discharge',
                    sortKey: 'POD',
                    id: 'POD',
                    parentId: 2
                },
                {
                    trKey: 'Labels.Forwarder',
                    trKeyM: 'Labels.Forwarder',
                    lblKey: 'forwarder',
                    name: 'Forwarder',
                    sortKey: 'FORWARDER',
                    id: 'FORWARDER',
                    parentId: 2
                },
                { trKey: 'Labels.Shipper', trKeyM: 'Labels.Shipper', lblKey: 'shipper', name: 'Shipper', sortKey: 'SHIPPER', id: 'SHIPPER', parentId: 2 }
            ],
            selectedColumns: [2, 'BL_NO', 'BL_TYPE', 'SAILING_DATE', 'POL', 'POD', 'VVL', 'FORWARDER', 'SHIPPER'],
            store: JSON.parse(localStorage.getItem('copyBl_table:')) || []
        };
    };
    ViewSettings.prototype.seawayBill = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 3 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', lblKey: 'BLNo', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 3 },
                {
                    trKey: 'Labels.SailingDate',
                    trKeyM: 'Labels.SailingDate',
                    lblKey: 'sailingDate',
                    name: 'Sailing Date',
                    sortKey: 'SAILING_DATE',
                    id: 'SAILING_DATE',
                    parentId: 3
                },
                {
                    trKey: 'Labels.VesselVoyageDirection', trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 3
                },
                { trKey: 'Labels.PortOfLoading', trKeyM: 'Labels.POL', lblKey: 'POL', name: 'Port of Loading', sortKey: 'POL', id: 'POL', parentId: 3 },
                { trKey: 'Labels.PortOfDischarge', trKeyM: 'Labels.POD', lblKey: 'POD', name: 'Port of Discharge', sortKey: 'POD', id: 'POD', parentId: 3 },
                {
                    trKey: 'Labels.Forwarder',
                    trKeyM: 'Labels.Forwarder',
                    lblKey: 'forwarder',
                    name: 'Forwarder',
                    sortKey: 'FORWARDER',
                    id: 'FORWARDER',
                    parentId: 3
                },
                { trKey: 'Labels.Shipper', trKeyM: 'Labels.Shipper', lblKey: 'shipper', name: 'Shipper', sortKey: 'SHIPPER', id: 'SHIPPER', parentId: 3 }
            ],
            selectedColumns: [3, 'BL_NO', 'SAILING_DATE', 'POL', 'POD', 'VVL', 'FORWARDER', 'SHIPPER'],
            store: JSON.parse(localStorage.getItem('seawayBill_table:')) || []
        };
    };
    ViewSettings.prototype.monitorData = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', name: 'Select All', id: 4 },
                { trKey: 'Labels.ContainerNo', name: 'Container No.', sortKey: 'CONTAINER_NO', id: 'CONTAINER_NO', parentId: 4 },
                { trKey: 'Labels.BLNo', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 4 },
                { trKey: 'Labels.Power', name: 'Power', sortKey: 'POWER', id: 'POWER', parentId: 4 },
                { trKey: 'Labels.SetPoint', name: 'Set Point', sortKey: 'SET_POINT', id: 'SET_POINT', parentId: 4 },
                { trKey: 'Labels.Supply', name: 'Supply', sortKey: 'SUPPLY', id: 'SUPPLY', parentId: 4 },
                { trKey: 'Labels.Return', name: 'Return', sortKey: 'RETURN', id: 'RETURN', parentId: 4 },
                { trKey: 'Labels.Route', name: 'Route', sortKey: 'ROUTE', id: 'ROUTE', parentId: 4 },
                { trKey: 'Labels.LastUpdate', name: 'Last Update', sortKey: 'LAST_UPDATE', id: 'LAST_UPDATE', parentId: 4 },
                { trKey: 'Labels.TripStatus', name: 'Trip Status', sortKey: 'TRIP_STATUS', id: 'TRIP_STATUS', parentId: 4 }
            ],
            sortedColumns: [
                { trKey: 'Labels.ContainerNo', text: 'Container No.', id: 'container' },
                { trKey: 'Labels.BLNo', text: 'B/L No.', id: 'bol' },
                { trKey: 'Labels.Power', text: 'Power', id: 'power' },
                { trKey: 'Labels.SetPoint', text: 'Set Point', id: 'tempSetPoint' },
                { trKey: 'Labels.Supply', text: 'Supply', id: 'suppleyTemp' },
                { trKey: 'Labels.Return', text: 'Return', id: 'returnTemp' },
                { trKey: 'Labels.Route', text: 'Route', id: 'offRoute' },
                { trKey: 'Labels.LastUpdate', text: 'Last Update', id: 'statusDate' },
                { trKey: 'Labels.TripStatus', text: 'Trip Status', id: 'tripStatus' }
            ],
            selectedColumns: [4, 'CONTAINER_NO', 'BL_NO', 'POWER', 'SET_POINT', 'SUPPLY', 'RETURN', 'ROUTE', 'LAST_UPDATE', 'TRIP_STATUS'],
            store: JSON.parse(localStorage.getItem('monitor_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.bookingConfirmation = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 5 },
                {
                    trKey: 'Labels.BookingNo',
                    trKeyM: 'Labels.BookingNo',
                    lblKey: 'BOOKINGNO',
                    name: 'Booking No.',
                    sortKey: 'BOOKING_REFERENCE',
                    id: 'BOOKING_NO',
                    parentId: 5
                },
                {
                    trKey: 'Labels.BookingDate',
                    trKeyM: 'Labels.BookingDate',
                    lblKey: 'BOOKINGDATE',
                    name: 'Booking Date',
                    sortKey: 'BOOKED_DATE',
                    id: 'BOOKED_DATE',
                    parentId: 5
                },
                {
                    trKey: 'Labels.SailingDate',
                    trKeyM: 'Labels.SailingDate',
                    lblKey: 'SAILINGDATE',
                    name: 'Sailing Date',
                    sortKey: 'SAILING_DATE',
                    id: 'SAILING_DATE',
                    parentId: 5
                },
                {
                    trKey: 'Labels.VesselVoyageDirection', trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 5
                },
                {
                    trKey: 'Labels.PlaceOfReceipt',
                    trKeyM: 'Labels.POR',
                    lblKey: 'POR',
                    name: 'Place of Receipt',
                    sortKey: 'ACCEPTANCE_PLACE',
                    id: 'ACCEPTANCE_PLACE',
                    parentId: 5
                },
                { trKey: 'Labels.PortOfLoading', trKeyM: 'Labels.POL', lblKey: 'POL', name: 'Port of Loading', sortKey: 'POL', id: 'POL', parentId: 5 },
                { trKey: 'Labels.PortOfDischarge', trKeyM: 'Labels.POD', lblKey: 'POD', name: 'Port of Discharge', sortKey: 'POD', id: 'POD', parentId: 5 },
                {
                    trKey: 'Labels.DeliveryPlace',
                    trKeyM: 'Labels.DeliveryPlace',
                    lblKey: 'DELIVERYPLACE',
                    name: 'Delivery Place',
                    sortKey: 'DELIVERY_PLACE',
                    id: 'DELIVERY_PLACE',
                    parentId: 5
                },
                { trKey: 'Labels.Shipper', trKeyM: 'Labels.Shipper', lblKey: 'shipper', name: 'Shipper', sortKey: 'SHIPPER', id: 'SHIPPER', parentId: 5 },
                { trKey: 'Labels.ETA', trKeyM: 'Labels.ETA', lblKey: 'eta', name: 'Estimated Time of Arrival', sortKey: 'ETA', id: 'ETA', parentId: 5 }
            ],
            selectedColumns: [5, 'BOOKING_NO', 'SAILING_DATE', 'VVL', 'POL', 'POD', 'SHIPPER',
                'BOOKED_DATE', 'ACCEPTANCE_PLACE', 'DELIVERY_PLACE', 'ETA'],
            store: JSON.parse(localStorage.getItem('bookingConfirmation_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.myShipmentsExport = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 6 },
                { trKey: 'Labels.Status', trKeyM: 'Labels.Status', name: 'Status', lblKey: 'status', sortKey: 'SOURCE_STATE', id: 'SOURCE_STATE', parentId: 6 },
                {
                    trKey: 'Labels.BookingNo',
                    trKeyM: 'Labels.BookingNo',
                    name: 'Booking No.',
                    lblKey: 'BookingNo',
                    sortKey: 'BOOKING_REFERENCE',
                    id: 'BOOKING_NO',
                    parentId: 6
                },
                {
                    trKey: 'Labels.BookingDate',
                    trKeyM: 'Labels.BookingDate',
                    name: 'Booking Date',
                    lblKey: 'BookingDate',
                    sortKey: 'BOOKED_DATE',
                    id: 'BKD_DATE',
                    parentId: 6
                },
                { trKey: 'Labels.BLType', trKeyM: 'Labels.BLType', name: 'B/L Type', lblKey: 'BLTYPE', sortKey: 'BILL_TYPE', id: 'BL_TYPE', parentId: 6 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', name: 'B/L No.', lblKey: 'BLNO', sortKey: 'BL_NO', id: 'BL_NO', parentId: 6 },
                {
                    trKey: 'Labels.SailingDate',
                    trKeyM: 'Labels.SailingDate',
                    name: 'Sailing Date',
                    lblKey: 'sailingDate',
                    sortKey: 'SAILING_DATE',
                    id: 'SAILING_DATE',
                    parentId: 6
                },
                {
                    trKey: 'Labels.VesselVoyageDirection', trKeyM: 'Labels.VVL',
                    name: 'Vessel / Voyage / Direction',
                    lblKey: 'vesselVoyageDirection',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 6
                },
                {
                    trKey: 'Labels.PlaceOfReceipt',
                    trKeyM: 'Labels.PlaceOfReceipt',
                    name: 'Place of Receipt',
                    lblKey: 'POR',
                    sortKey: 'ACCEPTANCE_PLACE',
                    id: 'RECEIPT_PLACE',
                    parentId: 6
                },
                { trKey: 'Labels.PortOfLoading', trKeyM: 'Labels.POL', name: 'Port of Loading', lblKey: 'POL', sortKey: 'POL', id: 'POL', parentId: 6 },
                { trKey: 'Labels.PortOfDischarge', trKeyM: 'Labels.POD', name: 'Port of Discharge', lblKey: 'POD', sortKey: 'POD', id: 'POD', parentId: 6 },
                {
                    trKey: 'Labels.DeliveryPlace',
                    trKeyM: 'Labels.DeliveryPlace',
                    name: 'Delivery Place',
                    lblKey: 'deliveryPlace',
                    sortKey: 'DELIVERY_PLACE',
                    id: 'DELIVERY_PLACE',
                    parentId: 6
                },
                { trKey: 'Labels.Shipper', trKeyM: 'Labels.Shipper', name: 'Shipper', lblKey: 'shipper', sortKey: 'SHIPPER', id: 'SHIPPER', parentId: 6 },
                {
                    trKey: 'Labels.EstimatedTimeOfArrival',
                    trKeyM: 'Labels.ETA',
                    name: 'Estimated Time of Arrival',
                    lblKey: 'ETA',
                    sortKey: 'ETA',
                    id: 'ETA',
                    parentId: 6
                }
            ],
            selectedColumns: [6, 'SOURCE_STATE', 'BOOKING_NO', 'BKD_DATE', 'BL_TYPE', 'BL_NO',
                'VVL', 'POL', 'POD', 'SAILING_DATE', 'RECEIPT_PLACE', 'DELIVERY_PLACE', 'SHIPPER', 'ETA'],
            store: JSON.parse(localStorage.getItem('myShipmentsExport_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.myShipmentsImport = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'selectAll', lblKey: 'selectAll', name: 'Select All', id: 7 },
                { trKey: 'Labels.Status', trKeyM: 'Labels.Status', lblKey: 'status', name: 'Status', sortKey: 'SOURCE_STATE', id: 'SOURCE_STATE', parentId: 7 },
                { trKey: 'Labels.BLType', trKeyM: 'Labels.BLType', lblKey: 'BLTYPE', name: 'B/L Type', sortKey: 'BILL_TYPE', id: 'BL_TYPE', parentId: 7 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', lblKey: 'BLNO', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 7 },
                {
                    trKey: 'Labels.Consignee',
                    trKeyM: 'Labels.Consignee',
                    lblKey: 'CONSIGNEE',
                    name: 'Consignee',
                    sortKey: 'CONSIGNEE',
                    id: 'CONSIGNEE',
                    parentId: 7
                },
                { trKey: 'Labels.Notify', trKeyM: 'Labels.Notify', lblKey: 'NOTIFY', name: 'Notify', sortKey: 'NOTIFY', id: 'NOTIFY', parentId: 7 },
                { trKey: 'Labels.Shipper', trKeyM: 'Labels.Shipper', lblKey: 'SHIPPER', name: 'Shipper', sortKey: 'SHIPPER', id: 'SHIPPER', parentId: 7 },
                {
                    trKey: 'Labels.VesselVoyageDirection', trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 7
                },
                {
                    trKey: 'Labels.PlaceOfReceipt',
                    trKeyM: 'Labels.PlaceOfReceipt',
                    lblKey: 'POR',
                    name: 'Port of Receipt',
                    sortKey: 'ACCEPTANCE_PLACE',
                    id: 'RECEIPT_PLACE',
                    parentId: 7
                },
                { trKey: 'Labels.PortOfLoading', trKeyM: 'Labels.POL', lblKey: 'POL', name: 'Port of Loading', sortKey: 'POL', id: 'POL', parentId: 7 },
                { trKey: 'Labels.PortOfDischarge', trKeyM: 'Labels.POD', lblKey: 'POD', name: 'Port of Discharge', sortKey: 'POD', id: 'POD', parentId: 7 },
                {
                    trKey: 'Labels.DeliveryPlace',
                    trKeyM: 'Labels.DeliveryPlace',
                    lblKey: 'deliveryPlace',
                    name: 'Delivery Place',
                    sortKey: 'DELIVERY_PLACE',
                    id: 'DELIVERY_PLACE',
                    parentId: 7
                },
                {
                    trKey: 'Labels.EstimatedTimeOfArrival',
                    trKeyM: 'Labels.ETA',
                    lblKey: 'ETA',
                    name: 'Estimated Time of Arrival',
                    sortKey: 'ETA',
                    id: 'ETA',
                    parentId: 7
                }
            ],
            selectedColumns: [7, 'SOURCE_STATE', 'BL_TYPE', 'BL_NO', 'VVL', 'POL', 'POD',
                'CONSIGNEE', 'NOTIFY', 'SHIPPER', 'RECEIPT_PLACE', 'DELIVERY_PLACE', 'ETA'],
            store: JSON.parse(localStorage.getItem('myShipmentsImport_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.arrivalNotice = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 8 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', lblKey: 'BLNo', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 8 },
                {
                    trKey: 'Labels.Consignee',
                    trKeyM: 'Labels.Consignee',
                    lblKey: 'consignee',
                    name: 'Consignee',
                    sortKey: 'CONSIGNEE',
                    id: 'CONSIGNEE',
                    parentId: 8
                },
                { trKey: 'Labels.Notify', trKeyM: 'Labels.Notify', lblKey: 'notify', name: 'Notify', sortKey: 'NOTIFY', id: 'NOTIFY', parentId: 8 },
                {
                    trKey: 'Labels.VesselVoyageDirection', trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 8
                },
                {
                    trKey: 'Labels.PlaceOfReceipt',
                    trKeyM: 'Labels.PlaceOfReceipt',
                    lblKey: 'POR',
                    name: 'Port of Receipt',
                    sortKey: 'ACCEPTANCE_PLACE',
                    id: 'RECEIPT_PLACE',
                    parentId: 8
                },
                { trKey: 'Labels.PortOfLoading', trKeyM: 'Labels.POL', lblKey: 'POL', name: 'Port of Loading', sortKey: 'POL', id: 'POL', parentId: 8 },
                { trKey: 'Labels.PortOfDischarge', trKeyM: 'Labels.POD', lblKey: 'POD', name: 'Port of Discharge', sortKey: 'POD', id: 'POD', parentId: 8 },
                {
                    trKey: 'Labels.DeliveryPlace',
                    trKeyM: 'Labels.DeliveryPlace',
                    lblKey: 'deliveryplace',
                    name: 'Delivery Place',
                    sortKey: 'DELIVERY_PLACE',
                    id: 'DELIVERY_PLACE',
                    parentId: 8
                },
                {
                    trKey: 'Labels.EstimatedTimeOfArrival',
                    trKeyM: 'Labels.ETA',
                    lblKey: 'eta',
                    name: 'Estimated Time of Arrival',
                    sortKey: 'ETA',
                    id: 'ETA',
                    parentId: 8
                }
            ],
            selectedColumns: [8, 'BL_NO', 'VVL', 'POL', 'POD', 'CONSIGNEE', 'NOTIFY', 'RECEIPT_PLACE', 'DELIVERY_PLACE', 'ETA'],
            store: JSON.parse(localStorage.getItem('arrivalNotice_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.deliveryOrder = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 9 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', lblKey: 'BLNo', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 9 },
                {
                    trKey: 'Labels.DeliveryOrder',
                    trKeyM: 'Labels.DeliveryOrder',
                    lblKey: 'DeliveryOrder',
                    name: 'Delivery Order',
                    sortKey: 'DO_REFERENCE',
                    id: 'DO_REFERENCE',
                    parentId: 9
                },
                {
                    trKey: 'Labels.Consignee',
                    trKeyM: 'Labels.Consignee',
                    lblKey: 'consignee',
                    name: 'Consignee',
                    sortKey: 'CONSIGNEE',
                    id: 'CONSIGNEE',
                    parentId: 9
                },
                { trKey: 'Labels.Notify', trKeyM: 'Labels.Notify', lblKey: 'notify', name: 'Notify', sortKey: 'NOTIFY', id: 'NOTIFY', parentId: 9 },
                {
                    trKey: 'Labels.VesselVoyageDirection', trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 9
                },
                {
                    trKey: 'Labels.PlaceOfReceipt',
                    trKeyM: 'Labels.PlaceOfReceipt',
                    lblKey: 'POR',
                    name: 'Port of Receipt',
                    sortKey: 'ACCEPTANCE_PLACE',
                    id: 'RECEIPT_PLACE',
                    parentId: 9
                },
                { trKey: 'Labels.PortOfLoading', trKeyM: 'Labels.POL', lblKey: 'POL', name: 'Port of Loading', sortKey: 'POL', id: 'POL', parentId: 9 },
                { trKey: 'Labels.PortOfDischarge', trKeyM: 'Labels.POD', lblKey: 'POD', name: 'Port of Discharge', sortKey: 'POD', id: 'POD', parentId: 9 },
                {
                    trKey: 'Labels.DeliveryPlace',
                    trKeyM: 'Labels.DeliveryPlace',
                    lblKey: 'deliveryplace',
                    name: 'Delivery Place',
                    sortKey: 'DELIVERY_PLACE',
                    id: 'DELIVERY_PLACE',
                    parentId: 9
                },
                {
                    trKey: 'Labels.EstimatedTimeOfArrival',
                    trKeyM: 'Labels.ETA',
                    lblKey: 'eta',
                    name: 'Estimated Time of Arrival',
                    sortKey: 'ETA',
                    id: 'ETA',
                    parentId: 9
                }
            ],
            selectedColumns: [9, 'BL_NO', 'DO_REFERENCE', 'VVL', 'POL', 'POD', 'CONSIGNEE',
                'NOTIFY', 'RECEIPT_PLACE', 'DELIVERY_PLACE', 'ETA'],
            store: JSON.parse(localStorage.getItem('deliveryOrder_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.copyInvoice = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', trKeyM: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 10 },
                { trKey: 'Labels.BLNo', trKeyM: 'Labels.BLNo', lblKey: 'BLNo', name: 'B/L No.', sortKey: 'BL_NO', id: 'BL_NO', parentId: 10 },
                {
                    trKey: 'Labels.InvoiceNo',
                    trKeyM: 'Labels.InvoiceNo',
                    lblKey: 'invoiceNo',
                    name: 'Invoice No.',
                    sortKey: 'INVOICE_NO',
                    id: 'INVOICE_NO',
                    parentId: 10
                },
                { trKey: 'Labels.Payer', trKeyM: 'Labels.Payer', lblKey: 'Payer', name: 'Payer', sortKey: 'PAYER', id: 'PAYER', parentId: 10 },
                { trKey: 'Labels.CP', trKeyM: 'Labels.CP', lblKey: 'CP', name: 'customer', sortKey: 'CONTRACT_PARTY', id: 'CONTRACT_PARTY', parentId: 10 },
                {
                    trKey: 'Labels.InvoiceDate',
                    trKeyM: 'Labels.InvoiceDate',
                    lblKey: 'invoiceDate',
                    name: 'Invoice Date',
                    sortKey: 'INVOICE_ISSUE_DATE',
                    id: 'INVOICE_ISSUE_DATE',
                    parentId: 10
                },
                {
                    trKey: 'Labels.PrepaidCollect', trKeyM: 'Labels.PrepaidCollect',
                    lblKey: 'PrepaidCollect',
                    name: 'Prepaid/Collect',
                    sortKey: 'PREPAID_COLLECT_IND',
                    id: 'PREPAID_COLLECT_IND',
                    parentId: 10
                },
                { trKey: 'Labels.Amount', trKeyM: 'Labels.Amount', lblKey: 'amount', name: 'Amount', sortKey: 'INVOICE_AMOUNT', id: 'INVOICE_AMOUNT', parentId: 10 },
                {
                    trKey: 'Labels.Currency',
                    trKeyM: 'Labels.Currency',
                    lblKey: 'currency',
                    name: 'Currency',
                    sortKey: 'INVOICE_CURRENCY',
                    id: 'INVOICE_CURRENCY',
                    parentId: 10
                },
                { trKey: 'Labels.Type', trKeyM: 'Labels.Type', lblKey: 'type', name: 'Type', sortKey: 'INVOICE_PROCESS_INDICATION', id: 'INVOICE_PROCESS_INDICATION', parentId: 10 },
                {
                    trKey: 'Labels.VesselVoyageDirection', trKeyM: 'Labels.VVL',
                    lblKey: 'vesselVoyageDirection',
                    name: 'Vessel / Voyage / Direction',
                    sortKey: 'VESSEL',
                    id: 'VVL',
                    parentId: 10
                },
                { trKey: 'Labels.PortOfLoading', trKeyM: 'Labels.POL', lblKey: 'POL', name: 'Port of Loading', sortKey: 'POL', id: 'POL', parentId: 10 },
                { trKey: 'Labels.PortOfDischarge', trKeyM: 'Labels.POD', lblKey: 'POD', name: 'Port of Discharge', sortKey: 'POD', id: 'POD', parentId: 10 },
                {
                    trKey: 'Labels.PaymentPlace',
                    trKeyM: 'Labels.PaymentPlace',
                    lblKey: 'paymentplace',
                    name: 'Payment Place',
                    sortKey: 'PLACE_PAYMENT',
                    id: 'PLACE_PAYMENT',
                    parentId: 10
                }
            ],
            selectedColumns: [10, 'BL_NO', 'INVOICE_NO', 'PAYER', 'CONTRACT_PARTY', 'INVOICE_PROCESS_INDICATION', 'INVOICE_ISSUE_DATE',
                'PREPAID_COLLECT_IND', 'INVOICE_AMOUNT', 'INVOICE_CURRENCY', 'VVL', 'POL', 'POD', 'PLACE_PAYMENT'],
            store: JSON.parse(localStorage.getItem('copyInvoice_table:')) || []
        };
    };
    ;
    ViewSettings.prototype.userManagement = function () {
        return {
            tableColumns: [
                { trKey: 'Labels.SelectAll', lblKey: 'selectAll', name: 'Select All', id: 11 },
                { trKey: 'Labels.UsersTable.FullName', lblKey: 'FULLNAME', name: 'Full Name', parentId: 11 },
                { trKey: 'Labels.UsersTable.Customer', lblKey: 'customer', name: 'Customer (CUCC)', parentId: 11 },
                { trKey: 'Labels.UsersTable.Status', lblKey: 'status', name: 'Status', parentId: 11 },
                { trKey: 'Labels.Admin', lblKey: 'Admin', name: 'ADMIN', parentId: 11 },
                { trKey: 'Labels.Booking', lblKey: 'Booking', name: 'BOOKING', parentId: 11 },
                { trKey: 'Labels.Export', lblKey: 'Export', name: 'EXPORT', parentId: 11 },
                { trKey: 'Labels.PrintBl', lblKey: 'PrintBL', name: 'PRINT_BL', parentId: 11 },
                { trKey: 'Labels.CopyBL', lblKey: 'CopyBL', name: 'COPY_BL', parentId: 11 },
                { trKey: 'Labels.FreightedInvoices', lblKey: 'FreightedInvoices', name: 'FINANCE', parentId: 11 },
                { trKey: 'Labels.Import', lblKey: 'Import', name: 'IMPORT', parentId: 11 },
                { trKey: 'Labels.Notifications', lblKey: 'Notifications', name: 'NOTIFICATIONS', parentId: 11 },
                { trKey: 'Labels.ZimMonitor', lblKey: 'ZIMonitor', name: 'ZIM_MONITOR', parentId: 11 }
            ]
        };
    };
    ViewSettings.prototype.getTableSettings = function (name) {
        return this[name]();
    };
    ViewSettings.prototype.getBLBKRegularExpressions = function () {
        var isTurnOn = false;
        return {
            BillOfLadingNo: isTurnOn ? '(ZIMU|OCEU|TUZU|VESU|zimu|oceu|tuzu|vesu)[a-zA-Z]{3}[0-9]{1,9}' : '',
            BookingNo: isTurnOn ? '^[a-zA-Z0-9]*$' : '',
            BillOfLadingAndBookingNo: isTurnOn ? '(^(ZIMU|OCEU|zimu|oceu)[a-zA-Z]{3}[0-9]{1,9}$)|(^[a-zA-Z0-9]*$)' : ''
        };
    };
    ViewSettings.prototype.getTableSettingsByRoute = function (routeName) {
        if (routeName) {
            return this.getTableSettings(this.RouteViewSettingsnMap[routeName]);
        }
        else {
            return this.getTableSettings(this.RouteViewSettingsnMap[this.currentRouteName]);
        }
    };
    return ViewSettings;
}());
export { ViewSettings };
