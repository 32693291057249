import {Subject} from 'rxjs';


export class LoaderService {

    loaderStateChanged = new Subject<boolean>();
    coverAllLoaderStateChanged = new Subject<boolean>();
    componentsLoaderStateChanged = new Subject<boolean>();

    showSpinner() {
        this.loaderStateChanged.next(true);
    }

    hideSpinner() {
        this.loaderStateChanged.next(false);
    }

    showCoverAllSpinner() {
        this.coverAllLoaderStateChanged.next(true);
    }

    hideCoverAllSpinner() {
        this.coverAllLoaderStateChanged.next(false);
    }

    showComponentsSpinner() {
        this.componentsLoaderStateChanged.next(true);
    }

    hideComponentsSpinner() {
        this.componentsLoaderStateChanged.next(false);
    }
}
