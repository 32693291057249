import {NgModule} from '@angular/core';
import {StatusWrapDirective} from 'app/shared/directives/status-wraper/status-wraper.directive';


@NgModule({
    declarations: [StatusWrapDirective],
    exports: [StatusWrapDirective]
})

export class StatusWrapModule {
}
