import { HttpClientService } from 'app/core/http/http-client.service';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
var ContainersTracingService = /** @class */ (function () {
    function ContainersTracingService(http, environmentConfig) {
        this.http = http;
        this.environmentConfig = environmentConfig;
        this.apiEndPoint = environmentConfig.get('commonEndPoints').tracing;
        this.baseApi = this.environmentConfig.get('baseApiUrl');
    }
    ContainersTracingService.prototype.getContainerTracing = function (data) {
        return this.http.post(this.baseApi + this.apiEndPoint, data);
    };
    return ContainersTracingService;
}());
export { ContainersTracingService };
