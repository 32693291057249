import { Subject } from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs';
var MySettingsShareDataService = /** @class */ (function () {
    function MySettingsShareDataService() {
        this.subject = new BehaviorSubject({});
        this.navLanguageSubject = new Subject();
    }
    MySettingsShareDataService.prototype.sendData = function (data) {
        this.subject.next(data);
    };
    MySettingsShareDataService.prototype.getData = function () {
        return this.subject.asObservable();
    };
    MySettingsShareDataService.prototype.setNavLanguage = function (data) {
        this.navLanguageSubject.next(data);
    };
    MySettingsShareDataService.prototype.getNavLanguage = function () {
        return this.navLanguageSubject.asObservable();
    };
    return MySettingsShareDataService;
}());
export { MySettingsShareDataService };
