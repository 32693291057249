import { OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ConfigService } from 'app/config/config.sevice';
import { AuthService } from 'app/auth/auth.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../../shared/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
var MaintanceErrorComponent = /** @class */ (function () {
    function MaintanceErrorComponent(configService, cookiesService, translateService, authService, router, loaderService, route) {
        this.configService = configService;
        this.cookiesService = cookiesService;
        this.translateService = translateService;
        this.authService = authService;
        this.router = router;
        this.loaderService = loaderService;
        this.route = route;
        this.isDefaultLanguage = false;
        this.isTryAgain = false;
    }
    MaintanceErrorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParamMap.subscribe(function (queryParams) {
            var lang = _this.cookiesService.get('language') || 'en';
            _this.msg = JSON.parse(queryParams.get('msg'));
            _this.isTryAgain = queryParams.get('isTryAgain') === 'true';
            if (_this.authService.isAuthentificated()) {
                _this.translateService.use(lang).subscribe(function (data) {
                    _this.isDefaultLanguage = data instanceof HttpErrorResponse;
                    _this.loaderService.hideCoverAllSpinner();
                }, function () {
                    _this.isDefaultLanguage = true;
                    _this.loaderService.hideCoverAllSpinner();
                });
            }
            else {
                setTimeout(function () { return _this.loaderService.hideCoverAllSpinner(); }, 1000);
                _this.isDefaultLanguage = true;
            }
        });
    };
    MaintanceErrorComponent.prototype.tryAgain = function () {
        this.router.toLogin();
    };
    return MaintanceErrorComponent;
}());
export { MaintanceErrorComponent };
