import { Router } from '@angular/router';
import { Injector } from '@angular/core';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
import { CookieService } from 'ngx-cookie';
var RouterWrapper = /** @class */ (function () {
    function RouterWrapper(injector, environmentConfiguration, cookieService) {
        this.injector = injector;
        this.environmentConfiguration = environmentConfiguration;
        this.cookieService = cookieService;
        this.apiEndPoint = environmentConfiguration.get('signIn');
    }
    Object.defineProperty(RouterWrapper.prototype, "router", {
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    RouterWrapper.prototype.toHome = function () {
        this.router.navigate(['/app']);
    };
    RouterWrapper.prototype.toLogin = function () {
        location.href = this.setUILanguage(this.apiEndPoint.loginLink);
    };
    RouterWrapper.prototype.toLogout = function () {
        location.href = this.setUILanguage(this.apiEndPoint.logoutLink);
    };
    RouterWrapper.prototype.toDashboard = function () {
        this.router.navigate(['/app/dashboard']);
    };
    RouterWrapper.prototype.toErrorPage = function (msg, isTryAgain) {
        if (msg === void 0) { msg = { translationKey: 'Errors.General.SystemUnderMaintenance' }; }
        if (isTryAgain === void 0) { isTryAgain = false; }
        var queryParams = {
            msg: JSON.stringify(msg),
            isTryAgain: isTryAgain
        };
        this.router.navigate(['/maintance-error'], {
            queryParams: queryParams
        });
    };
    RouterWrapper.prototype.toMaintancePage = function () {
        this.router.navigate(['/maintance-error']);
    };
    RouterWrapper.prototype.setUILanguage = function (url) {
        var pattern = new RegExp('\\b(ui_locales=).*?(&|$)');
        var language = this.cookieService.get('language') || 'en';
        if (url.search(pattern) >= 0) {
            return url.replace(pattern, '$1' + language + '$2');
        }
        url = url.replace(/\?$/, '');
        return url + (url.indexOf('?') > 0 ? '&' : '?') + 'ui_locales' + '=' + language;
    };
    RouterWrapper.prototype.toPage = function (url) {
        this.router.navigate([url]);
    };
    return RouterWrapper;
}());
export { RouterWrapper };
