import { HttpClientService } from '../../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../../config/evironment-config';
var GdprPopupService = /** @class */ (function () {
    function GdprPopupService(httpClient, environmentConfiguration) {
        this.httpClient = httpClient;
        this.environmentConfiguration = environmentConfiguration;
        this.apiEndPoint = environmentConfiguration.get('gdprEndPoint');
        this.baseApi = environmentConfiguration.get('baseApiUrl');
        this.contactsApiEndpoint = this.environmentConfiguration.get('getmycontactEndPoint').contacts;
    }
    GdprPopupService.prototype.getMyContact = function () {
        return this.httpClient.get(this.baseApi + this.contactsApiEndpoint);
    };
    GdprPopupService.prototype.getPurpose = function (categoryname, language, email) {
        var query = "?category=" + categoryname + "&lang=" + language + "&email=" + email;
        return this.httpClient.get(this.baseApi + this.apiEndPoint.getPurpose + query);
    };
    GdprPopupService.prototype.setNewGdprConfirmation = function (request) {
        return this.httpClient.post(this.baseApi + this.apiEndPoint.newGdprConfirmation, request);
    };
    GdprPopupService.prototype.checkConsent = function (purposeId) {
        var request = {
            purposeId: purposeId
        };
        return this.httpClient.post(this.baseApi + this.apiEndPoint.checkConsent, request);
    };
    return GdprPopupService;
}());
export { GdprPopupService };
