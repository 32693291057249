import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

interface SearchPanelField {
    Id: string;
    Title: string;
    Placeholder?: string;
    NgClass?: string;
    Validators?: object;
    Data?: object;
    InitValue?: any;
}

export class SearchPanelConfig {
    BNo: SearchPanelField;
    POL: SearchPanelField;
    POD: SearchPanelField;
    FromDate: SearchPanelField;
    ToDate: SearchPanelField;
    Vessel: SearchPanelField;
    Voyage: SearchPanelField;
    Direction: SearchPanelField;
    Customer: SearchPanelField;
    Status: SearchPanelField;
    Original?: SearchPanelField;
    Waybill?: SearchPanelField;
    InvoiceNo?: SearchPanelField;
    Prepaid?: SearchPanelField;
    Collect?: SearchPanelField;
    Payer?: SearchPanelField;
}

@Injectable()
export class SearchPanelConfigService {
    constructor(private translateService: TranslateService) {
    }

    getGeneralConfig(): SearchPanelConfig {
        return {
            BNo: {
                Id : 'blNo',
                Title: 'Labels.BLNo',
            },
            POL: {
                Id : 'polCode',
                Title: 'Labels.PortOfLoading',
                Placeholder: this.translateService.instant('Labels.PortOfLoadingPlaceholder')
            },
            POD: {
                Id : 'podCode',
                Title: 'Labels.PortOfDischarge',
                Placeholder: this.translateService.instant('Labels.PortOfDischargePlaceholder')
            },
            FromDate: {
                Id : 'fromDate',
                Title: 'Labels.SailingDateFrom',
            },
            ToDate: {
                Id : 'toDate',
                Title: 'Labels.SailingDateTo',
            },
            Vessel: {
                Id : 'vesselCode',
                Title: 'Labels.Vessel',
                Placeholder: this.translateService.instant('Labels.VesselPlaceholder')
            },
            Voyage: {
                Id : 'voyageCode',
                Title: 'Labels.Voyage',
                Placeholder: this.translateService.instant('Labels.VoyagePlaceholder')
            },
            Direction: {
                Id : 'legCode',
                Title: 'Labels.Direction',
                Placeholder: this.translateService.instant('Labels.DirectionPlaceholder')
            },
            Customer: {
                Id : 'customerList',
                Title: 'Labels.Customer',
                Placeholder: this.translateService.instant('Labels.SelectCustomers')
            },
            Status: {
                Id : 'statusList',
                Title: 'Labels.Status',
                Placeholder: this.translateService.instant('Labels.Status')
            }
        };
    }
}
