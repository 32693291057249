import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';

@Injectable()
export class FileService {

    constructor() {
    }

    saveFile(data: any, fileName: string, toBase64: boolean = true) {
        const base = toBase64 ? this.base64ToArrayBuffer(data) : data;
        const blob = new Blob([base], {type: 'application/octet-stream'});
        FileSaver.saveAs(blob, fileName);
    }

    defaultSave(blob: any, fileName: string) {
        FileSaver.saveAs(blob, fileName);
    }

    private base64ToArrayBuffer(base64) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }

        return bytes.buffer;
    }
}
