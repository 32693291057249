/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./config/evironment-config";
import * as i8 from "Angular2Piwik/dist/piwik-functions/configure.piwik.tracker";
import * as i9 from "Angular2Piwik/dist/piwik-functions/use.piwik.tracker";
import * as i10 from "./shared/services/accessibility.service";
import * as i11 from "./shared/services/loader.service";
import * as i12 from "./shared/services/storage-data-reader.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "global-loader"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "cover-all": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "loader common"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "se-pre-con"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "global-loader"; var currVal_1 = _ck(_v, 2, 0, _co.coverAllLoader); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "btn-user"], ["role", "button"], ["tabindex", "0"]], [[1, "aria-pressed", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchAccessibilityMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "skip-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "skip-main"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.initBlur($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 10, 0); var currVal_4 = (_co.loader || _co.coverAllLoader); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAccessibilityMode; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Labels.SrOnlyAccessibilityMode")); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.skipMainLink; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Labels.SkipToMainContent")); _ck(_v, 6, 0, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i5.AppComponent, [i4.Router, i4.ActivatedRoute, i6.Title, i7.EnvironmentConfiguration, i8.ConfigurePiwikTracker, i9.UsePiwikTracker, i3.TranslateService, i10.AccessibilityService, i11.LoaderService, i12.StorageDataReader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
