<navbar [hideLangSwitcher]="true"></navbar>
<div class="dashboard-wrap">
    <div class="col-lg-12 col-md-12 content-holder">
        <div class="monitor-intro">
            <div class="row">
                <div class="col-sm-12" *ngIf="isDefaultLanguage">
                    <div class="notice white-box">
                        <h3>Dashboard</h3>
                        <p>Welcome to MyZIM, your personal area at ZIM.com</p>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="!isDefaultLanguage">
                    <div class="notice white-box">
                        <h3 [innerHTML]="'DASHBOARD.ImportantNotice' | translate"></h3>
                        <p [innerHTML]="'DASHBOARD.ImportantNotice.Content' | translate"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="error-page" class="maintance col-sm-12">
        <div class="mask-copy">
            <div class="hi-there">
                <ng-container *ngIf="msg.text">{{msg.text}}</ng-container>
                <ng-container *ngIf="isDefaultLanguage && !msg.text">Service currently unavailable. Please try again later.</ng-container>
                <ng-container *ngIf="!isDefaultLanguage && !msg.text && msg.translationKey">{{msg.translationKey | translate: msg.params}}</ng-container>
            </div>
            <div class="try-again"  *ngIf="isTryAgain">
                <input class="btn btn-default" type="button" [value]="'OK'" (click)="tryAgain()">
            </div>
        </div>
    </div>
</div>
