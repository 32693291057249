import { OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
import { ConfigurePiwikTracker, UsePiwikTracker } from 'Angular2Piwik';
import { TranslateService } from '@ngx-translate/core';
import { AccessibilityService } from './shared/services/accessibility.service';
import { LoaderService } from './shared/services/loader.service';
import { StorageDataReader } from './shared/services/storage-data-reader.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, activatedRoute, titleService, environmentConfig, configurePiwikTracker, usePiwikTracker, translateService, accessibilityService, loaderService, localStor) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.titleService = titleService;
        this.environmentConfig = environmentConfig;
        this.configurePiwikTracker = configurePiwikTracker;
        this.usePiwikTracker = usePiwikTracker;
        this.translateService = translateService;
        this.accessibilityService = accessibilityService;
        this.loaderService = loaderService;
        this.localStor = localStor;
        this.isAccessibilityMode = false;
        this.loader = false;
        this.coverAllLoader = true;
        this.loaderService.loaderStateChanged.subscribe(function (state) {
            _this.loader = state;
        });
        this.loaderService.coverAllLoaderStateChanged.subscribe(function (state) {
            _this.coverAllLoader = state;
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.removeOldColumns();
        var thisUrl = '';
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                thisUrl = event.url;
            }
            if (event instanceof NavigationEnd) {
                _this.skipMainLink = window.location.href.includes('#') ? window.location.href : window.location.href + "#main";
            }
        });
        this.router.events
            .filter(function (event) { return event instanceof NavigationEnd; })
            .map(function () { return _this.activatedRoute; })
            .map(function (route) {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        })
            .filter(function (route) { return route.outlet === 'primary'; })
            .mergeMap(function (route) { return route.data; })
            .subscribe(function (event) { return _this.afterRoute(event, thisUrl); });
        this.translateService.onLangChange.subscribe(function (event) {
            _this.accessibilityService.updateLangOnHtml(event.lang);
        });
    };
    AppComponent.prototype.afterRoute = function (event, url) {
        var _this = this;
        if (event['title']) {
            this.titleService.setTitle(this.translateService.instant(event['title']));
            this.translateService.onLangChange.subscribe(function () {
                _this.titleService.setTitle(_this.translateService.instant(event['title']));
            });
        }
        this.configurePiwikTracker.setDocumentTitle();
        this.configurePiwikTracker.setCustomUrl(url);
        this.configurePiwikTracker.setReferrerUrl(window.location.href);
        // this.configurePiwikTracker.setCustomVariable(1, 'userId', 'idValue', 'visit');
        var userData = this.localStor.getZimAuthData();
        if (userData) {
            this.configurePiwikTracker.setUserId(userData.contact.userName);
        }
        this.usePiwikTracker.trackPageView();
    };
    AppComponent.prototype.removeOldColumns = function () {
        // we should remove old aliases
        localStorage.removeItem('copyBl_table');
        localStorage.removeItem('bookingConfirmation_table');
        localStorage.removeItem('myShipmentsExport_table');
        localStorage.removeItem('seawayBill_table');
        localStorage.removeItem('copyInvoice_table');
        localStorage.removeItem('arrivalNotice_table');
        localStorage.removeItem('deliveryOrder_table');
        localStorage.removeItem('myShipmentsImport_table');
        localStorage.removeItem('monitor_table');
        localStorage.removeItem('print_table');
        var sort = localStorage.getItem('copyInvoice_sort:');
        if (sort === 'INVOICE_DATE') {
            localStorage.removeItem('copyInvoice_sort:');
        }
    };
    AppComponent.prototype.initBlur = function (event) {
        if (event.target.localName === 'input' || event.target.localName === 'select' || event.target.className === 'sideBarActiveLink') {
            return;
        }
        else {
            event.target.blur();
        }
    };
    AppComponent.prototype.switchAccessibilityMode = function () {
        this.isAccessibilityMode = !this.isAccessibilityMode;
        if (this.isAccessibilityMode) {
            this.accessibilityService.enableAccessibilityMode();
        }
        else {
            this.accessibilityService.disableAccessibilityMode();
        }
        return false;
    };
    return AppComponent;
}());
export { AppComponent };
Date.prototype.toFilterString = function () {
    return this.getFullYear() + "-" + ('0' + (this.getMonth() + 1)).slice(-2) + "-" + ('0' + this.getDate()).slice(-2);
};
