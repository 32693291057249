/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./auth.component";
import * as i2 from "../auth.service";
import * as i3 from "@angular/router";
import * as i4 from "ngx-cookie/src/cookie.service";
import * as i5 from "../../core/routing/router.wrapper";
import * as i6 from "../../shared/components/message-modal/message-modal.service";
import * as i7 from "../../config/config.sevice";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../config/evironment-config";
import * as i10 from "../../shared/services/error-handler.service";
var styles_AuthComponent = [];
var RenderType_AuthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AuthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-details", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i0.ɵdid(1, 114688, null, 0, i1.AuthComponent, [i2.AuthService, i3.ActivatedRoute, i4.CookieService, i5.RouterWrapper, i6.MessageModalService, i7.ConfigService, i8.TranslateService, i9.EnvironmentConfiguration, i10.ErrorHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i0.ɵccf("app-details", i1.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
