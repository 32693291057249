
import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClientService } from 'app/core/http/http-client.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { Subject ,  Observable } from 'rxjs';
import { JwtHelper } from 'app/shared/helpers/JWTParser';
import { EnvironmentConfiguration } from 'app/config/evironment-config';

@Injectable()
export class AuthService {
    baseApi: any;
    jwtHelper: JwtHelper;
    private authState: Subject<any> = new Subject();

    constructor(private httpClient: HttpClientService,
         private environmentConfig: EnvironmentConfiguration,
         private router: RouterWrapper,
         private cookieService: CookieService) {
         this.jwtHelper = new JwtHelper();
         this.baseApi = environmentConfig.get('baseApiUrl');
    }


    getAuthState(): Subject<any> {
        return this.authState;
    }

    setAuthState(auth: boolean): void {
        this.authState.next(auth);
    }

    isAuthentificated(): boolean {
        return !!this.cookieService.get('zim_access_token');
    }


    getAuthData() {

        const validDsa = this.checkTokenDsa(this.cookieService.get('zim_access_token'));

        if (!validDsa) {
            return Observable.empty();
        }

        return this.httpClient.get(this.baseApi + this.environmentConfig.get('getmycontactEndPoint').contacts);
    }

    setAuthData(data: any) {
        sessionStorage.setItem('zim_auth_data', JSON.stringify(data));

        this.setAuthState(true);
    }

    removeAuthData() {
        sessionStorage.removeItem('zim_auth_data');
        this.setAuthState(false);
    }

    checkTokenDsa(token: string): boolean {
         const parsedObj = this.jwtHelper.decodeToken(token);
         return parsedObj && parsedObj['extension_DSAContactId'] !== '';
    }

    logout(): void {
        this.clearAuthorizationData();
        this.router.toLogout();
    }

    clearAuthorizationData(): void {
        this.cookieService.remove('zim_access_token');
        this.cookieService.remove('refresh_token');
        this.cookieService.remove('zim_customer_services');
        this.cookieService.remove('expires_in');
        this.removeAuthData();
    }
}
