import {NgModule} from '@angular/core';
import {PagingInfoDirective} from './paging-info.directive';

@NgModule({
    declarations: [
        PagingInfoDirective
    ],
    exports: [
        PagingInfoDirective
    ]
})
export class PagingInfoModule {
}
