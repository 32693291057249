import {NgModule} from '@angular/core';
import {PlaceholderTranslaterDirective} from 'app/shared/directives/select2-placeholder-translater/placeholder-translater.directive';


@NgModule({
    declarations: [
        PlaceholderTranslaterDirective
    ],
    exports: [
        PlaceholderTranslaterDirective
    ]
})

export class PlaceholderTranslaterModule {
}
