import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {AppRoutingModule} from 'app/app-routing.module';
import {AuthComponent} from 'app/auth/azure-auth/auth.component';
import {Angular2PiwikModule} from 'Angular2Piwik';
import {CoreModule} from 'app/core/core.module';
import {ErrorModule} from 'app/containers/error-page/error-page.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import localeKo from '@angular/common/locales/ko';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHant from '@angular/common/locales/zh-Hant';
registerLocaleData(localeHe);
registerLocaleData(localeKo);
registerLocaleData(localeZhHans);
registerLocaleData(localeZhHant);

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent
    ],
    imports: [
        CoreModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        Angular2PiwikModule,
        ErrorModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            maxOpened: 2,
            autoDismiss: true,
            preventDuplicates: true
        })
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {
}
