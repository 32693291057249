import {MaintanceErrorComponent} from 'app/containers/error-page/maintance-error/maintance-error';
import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {NavbarService} from 'app/containers/layout/navbar/navbar.service';

@NgModule({
    declarations: [
        MaintanceErrorComponent
    ],
    imports: [SharedModule, TranslateModule],
    providers: [NavbarService]
})
export class ErrorModule {
}
