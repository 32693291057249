import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClientService} from '../../core/http/http-client.service';
import {EnvironmentConfiguration} from '../../config/evironment-config';
import {Subject} from 'rxjs';

import {NotificationsCounter, NotificationsDailyReports, NotificationsEvents, NotificationsVesselBls} from './notifications.interface';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    baseApi: string;
    notificationsEndPoint: any;
    resetState = new Subject<boolean>();

    constructor(
        private httpClient: HttpClientService,
        private environmentConfiguration: EnvironmentConfiguration) {
        this.baseApi = environmentConfiguration.get('baseApiUrl');
        this.notificationsEndPoint = environmentConfiguration.get('notificationsEndPoint');
    }

    getNewNotificationsCounter(dsaId): Observable<NotificationsCounter> {
        return this.httpClient.get(this.baseApi + this.notificationsEndPoint.getNewNotificationsCounterConsumer + `?dsaID=${dsaId}`);
    }

    getNotificationEvents(data: any): Observable<NotificationsEvents> {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getNotificationEventsConsumer, data);
    }

    resetNotificationsCounter(data: any): Observable<any> {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.resetNotificationsCounterConsumer, data);
    }

    getDailyReports(data: any): Observable<NotificationsDailyReports> {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getDailyReportsConsumer, data);
    }

    getDailyReportDocument(data: any): Observable<any> {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getDailyReportDocumentConsumer, data);
    }

    getVesselEventBLs(data: any): Observable<NotificationsVesselBls> {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getVesselEventBLsConsumer, data);
    }

    setResetState(state: boolean) {
        this.resetState.next(state);
    }

    getResetState(): Observable<boolean> {
        return this.resetState.asObservable();
    }
}
