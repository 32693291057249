var EnvironmentConfiguration = /** @class */ (function () {
    function EnvironmentConfiguration() {
        this.staticConfig = {};
        this.RouteApiEndpointMap = {
            'print': 'printEndPoint',
            'zim-monitor': 'monitorEndPoint',
            'imp-my-shipments': 'myShipmentsImportEndPoint',
            'exp-my-shipments': 'myShipmentsExportEndPoint',
            'arrival-notice': 'arrivalNoticeEndPoint',
            'delivery-order': 'deliveryOrderEndPoint',
            'booking-confirmation': 'bookingConfirmation',
            'bl-copy': 'blCopysEndPoint',
            'seaway-bill': 'seawayBillEndPoint',
            'finance': 'financeEndPoint',
            'dashboard': 'dashboardEndPoint',
            'user-management': 'userManagementEndPoint'
        };
    }
    EnvironmentConfiguration.prototype.loadConfig = function () {
        var _this = this;
        var stcConf = window['caStaticEnvironmentConfig'];
        var envConf = window['caEnvironmentConfig'];
        return new Promise(function (resolve) {
            _this.staticConfig = [];
            for (var key in stcConf) {
                _this.staticConfig[key] = stcConf[key];
            }
            for (var key in envConf) {
                _this.staticConfig[key] = envConf[key];
            }
            resolve(true);
        });
    };
    EnvironmentConfiguration.prototype.get = function (key) {
        return this.staticConfig[key];
    };
    EnvironmentConfiguration.prototype.getByRoute = function (routeName) {
        return this.get(this.RouteApiEndpointMap[routeName]);
    };
    return EnvironmentConfiguration;
}());
export { EnvironmentConfiguration };
