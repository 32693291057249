import { SharedDataService } from 'app/shared/services/shared-data.service';
import { MessageModalService } from 'app/shared/components/message-modal/message-modal.service';
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
            providers: [SharedDataService, MessageModalService]
        };
    };
    return SharedModule;
}());
export { SharedModule };
