
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {ConfigService} from 'app/config/config.sevice';
import {RequestHelper} from 'app/core/http/request-helper';
import {HttpClient, HttpHeaders} from '@angular/common/http';

/* we need reuse the same countryCode therefore requesthelper was created for config.service*/

@Injectable()
export class HttpClientService {
    private lastRequestDate: Number;

    constructor(private http: HttpClient, private requestHelper: RequestHelper, private configService: ConfigService) {
    }

    get(url: string, headers?: HttpHeaders, anonymous: boolean = false): Observable<any> {

        headers = this.requestHelper.initHeaders(headers, anonymous);

        this.settingsHandler();

        return this.http.get(url, {headers: headers}).catch(error => this.requestHelper.errorHandler(error));
    }

    post(url: string, parameters: any, headers?: HttpHeaders, anonymous: boolean = false): Observable<any> {

        headers = this.requestHelper.initHeaders(headers, anonymous);

        this.settingsHandler();

        return this.http.post(url, parameters, {headers: headers}).catch(error => this.requestHelper.errorHandler(error));
    };

    settingsHandler() {
        if (this.lastRequestDate && this.lastRequestDate < new Date().getTime() - 10000) {
            this.configService.loadConfig();
        }

        this.lastRequestDate = new Date().getTime();
    }
}
