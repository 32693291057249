import {NgModule} from '@angular/core';
import {Select2IdDirective} from './select2-id.directive';

@NgModule({
    declarations: [
        Select2IdDirective
    ],
    exports: [
        Select2IdDirective
    ]
})

export class Select2IdModule {
}
