import { TranslateService } from '@ngx-translate/core';
var SearchPanelConfig = /** @class */ (function () {
    function SearchPanelConfig() {
    }
    return SearchPanelConfig;
}());
export { SearchPanelConfig };
var SearchPanelConfigService = /** @class */ (function () {
    function SearchPanelConfigService(translateService) {
        this.translateService = translateService;
    }
    SearchPanelConfigService.prototype.getGeneralConfig = function () {
        return {
            BNo: {
                Id: 'blNo',
                Title: 'Labels.BLNo',
            },
            POL: {
                Id: 'polCode',
                Title: 'Labels.PortOfLoading',
                Placeholder: this.translateService.instant('Labels.PortOfLoadingPlaceholder')
            },
            POD: {
                Id: 'podCode',
                Title: 'Labels.PortOfDischarge',
                Placeholder: this.translateService.instant('Labels.PortOfDischargePlaceholder')
            },
            FromDate: {
                Id: 'fromDate',
                Title: 'Labels.SailingDateFrom',
            },
            ToDate: {
                Id: 'toDate',
                Title: 'Labels.SailingDateTo',
            },
            Vessel: {
                Id: 'vesselCode',
                Title: 'Labels.Vessel',
                Placeholder: this.translateService.instant('Labels.VesselPlaceholder')
            },
            Voyage: {
                Id: 'voyageCode',
                Title: 'Labels.Voyage',
                Placeholder: this.translateService.instant('Labels.VoyagePlaceholder')
            },
            Direction: {
                Id: 'legCode',
                Title: 'Labels.Direction',
                Placeholder: this.translateService.instant('Labels.DirectionPlaceholder')
            },
            Customer: {
                Id: 'customerList',
                Title: 'Labels.Customer',
                Placeholder: this.translateService.instant('Labels.SelectCustomers')
            },
            Status: {
                Id: 'statusList',
                Title: 'Labels.Status',
                Placeholder: this.translateService.instant('Labels.Status')
            }
        };
    };
    return SearchPanelConfigService;
}());
export { SearchPanelConfigService };
