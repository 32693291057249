import { MessageModalService } from 'app/shared/components/message-modal/message-modal.service';
import { TranslateService } from '@ngx-translate/core';
import {ElementRef, Injectable, QueryList} from '@angular/core';
import { ConfigService } from 'app/config/config.sevice';
import { ScrollingService } from 'app/shared/services/scrolling.service';
import { PlaceholderTranslaterDirective } from 'app/shared/directives/select2-placeholder-translater/placeholder-translater.directive';
import ISettings from '../../shared/interfaces/setting.interface';
import ITableColumn from '../../shared/interfaces/table-column.interface';
import ISortedColumn from '../../shared/interfaces/sorted-column.interface';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { HttpClientService } from '../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../config/evironment-config';
import { map, tap } from 'rxjs/operators';
import {MobileService} from '../services/mobile.service';

@Injectable()
export class ScreenGeneral {
    selectAllId = 77777;
    baseApi: any;
    commonEndPoints: any;
    errors;

    constructor(
        private messageModalService: MessageModalService,
        private translateService: TranslateService,
        private configService: ConfigService,
        private scrollingService: ScrollingService,
        private cookieService: CookieService,
        private httpClient: HttpClientService,
        private mobileService: MobileService,
        private environmentConfiguration: EnvironmentConfiguration
    ) {
        this.baseApi = environmentConfiguration.get('baseApiUrl');
        this.commonEndPoints = environmentConfiguration.get('commonEndPoints');
    }

    // build pickList
    buildFilter(response, concatProperties: Array<any>, needDefaultOption: Array<any> = ['SOURCE_STATE', 'CUSTOMER']): any {

        const filterData = {};

        if (+response.esbResponse.returnCode < 0) {
            this.messageModalService.callModal(this.translateService.instant('Errors.General.GeneralError'),
                this.translateService.instant('Errors.General.GeneralTitle'));
        }

        if (response.pickListItems) {
            for (const filter of response.pickListItems) {
                // for (let i = 0; i < filter.pickListValues.length; i++) {
                //     if (!filter.pickListValues[i].code && filter.pickListValues[i].description) {
                //         filter.pickListValues[i].code = filter.pickListValues[i].description;
                //     } else if (filter.pickListValues[i].code && !filter.pickListValues[i].description) {
                //         filter.pickListValues[i].description = filter.pickListValues[i].code;
                //     }
                // }

                // filter.pickListValues = filter.pickListValues.filter(item => item.code && item.description);

                let items = filter.pickListValues.map((data): Object => {
                    return {
                        id: data.code,
                        [filter.fieldName === 'CUSTOMER' || filter.fieldName === 'SOURCE_STATE' ? 'name' : 'text']:
                            this.createFilterText(data.description, data.code, filter.fieldName, concatProperties),
                        parentId: this.selectAllId
                    };
                });

                if (filter.fieldName === 'CUSTOMER' || filter.fieldName === 'SOURCE_STATE') {
                    if (items.length > 1) {
                        items.unshift({ id: this.selectAllId, name: this.translateService.instant('Labels.SelectAll') });
                    }
                }

                if (filter.fieldName === 'VOYAGE' || filter.fieldName === 'IMP_VOYAGE') {
                    items = items.sort(function (a, b) {
                        return a.text - b.text
                    });
                }

                if (needDefaultOption.indexOf(filter.fieldName) === -1) {
                    items.unshift({ id: '', text: '' });
                }

                filterData[filter.fieldName] = items;
            }
        }

        return filterData;
    }

    // callGeneralError
    screenGeneralError() {
        this.messageModalService.callModal(this.translateService.instant('Errors.General.GeneralError'),
            this.translateService.instant('Errors.General.GeneralTitle'));
    }

    // concate value and description for some fields
    createFilterText(description: string, value: string, fieldName: string, concatProperties: Array<any>): string {
        return concatProperties.indexOf(fieldName) > -1 ? `${description} - ${value}` : description || value;
    }

    // get bls by type from print dropdown
    getBLsByType(tableData: any, selectedType: string): Array<any> {
        const bls = [];

        for (let i = 0; i < tableData.items.length; i++) {
            if (selectedType === 'Original') {
                if (tableData.items[i].type === 'ORIGINAL') {
                    bls.push(tableData.items[i].blNo);
                }
            } else if (selectedType === 'Sea Waybill') {
                if (tableData.items[i].type === 'WAYBILL') {
                    bls.push(tableData.items[i].blNo);
                }
            }
        }

        return bls;
    }

    getSelectedItems(tableData: any, key: string = 'blNo'): Array<any> {
        const bls = [];

        for (let i = 0; i < tableData.items.length; i++) {
            if (tableData.items[i].selected) {
                bls.push(tableData.items[i][key]);
            }
        }

        return bls;
    };

    // selectRow(row, event, tableData:any, printLimitKey:string) {
    //     const selectedAmount = tableData.items.filter(function (s) { return s.selected; }).length;
    //     const printLimit = this.configService.get(printLimitKey) ? this.configService.get(printLimitKey) : 5;
    //     if (selectedAmount >= printLimit && event.target.checked) {
    //       row.selected = false;
    //       event.target.checked = false;
    //       this.messageModalService.callModal(this.translateService.instant('Errors.General.AllowedNumber',
    //                                { number: printLimit }), this.translateService.instant('Errors.General.GeneralTitle'));
    //       return;
    //     } else {
    //       if (row.status == 'READY_TO_PRINT' && row.virtContactAction == 'PRINT') {
    //         row.selected = !(row.selected)
    //         this.buildDataForPrintPicker();
    //       }
    //     }
    // }
    removeFilterEmptyFields(filter: any): any {
        const copyFilter = { ...filter };

        Object.keys(copyFilter).forEach((key) => {
            if (copyFilter.statusList) {
                copyFilter.statusList = [...copyFilter.statusList];
                for (let i = 0; i < copyFilter.statusList.length; i++) {
                    if (copyFilter.statusList[i] === this.selectAllId) {
                        copyFilter.statusList.splice(i, 1);
                    }
                }
            }
            if (copyFilter.status) {
                copyFilter.status = [...copyFilter.status];
                for (let i = 0; i < copyFilter.status.length; i++) {
                    if (copyFilter.status[i] === this.selectAllId) {
                        copyFilter.status.splice(i, 1);
                    }
                }
            }

            if (copyFilter.customerList) {
                copyFilter.customerList = [...copyFilter.customerList];
                for (let i = 0; i < copyFilter.customerList.length; i++) {
                    if (copyFilter.customerList[i] === this.selectAllId) {
                        copyFilter.customerList.splice(i, 1);
                    }
                }
            }
            if (copyFilter.customer) {
                copyFilter.customer = [...copyFilter.customer];
                for (let i = 0; i < copyFilter.customer.length; i++) {
                    if (copyFilter.customer[i] === this.selectAllId) {
                        copyFilter.customer.splice(i, 1);
                    }
                }
            }

            if (!copyFilter[key]) {
                delete copyFilter[key];
            } else if (copyFilter[key].hasOwnProperty('fromDate') && copyFilter[key].hasOwnProperty('toDate')) {
                if (!copyFilter[key].fromDate && !copyFilter[key].toDate) {
                    delete copyFilter[key];
                } else {
                    if (copyFilter[key].fromDate && !copyFilter[key].toDate) {
                        copyFilter[key].toDate = this.AddYear(copyFilter[key].fromDate, 1);
                    } else if (!copyFilter[key].fromDate && copyFilter[key].toDate) {
                        copyFilter[key].fromDate = this.AddYear(copyFilter[key].toDate, -1);
                    }
                    copyFilter[key].fromDate = copyFilter[key].fromDate.toFilterString();
                    copyFilter[key].toDate = copyFilter[key].toDate.toFilterString();
                }
            }
        });

        return copyFilter;
    }

    scrollUp() {
        this.scrollingService.scrollUp('search-table-holder', 500, 50, true);
    }

    checkSelectedRows(tableData: any, selectedRows: Array<any>, key: string) {
        for (let i = 0; i < selectedRows.length; i++) {
            const index = tableData.items.findIndex(item => item[key] === selectedRows[i][key]);
            if (index > -1) {
                tableData.items[index]['selected'] = true;
            }
        }
    };

    selectedColumnsToObject(settings: any, tableAlias: string, tableColumns: Array<string>): any {
        const columns = {};
        settings.selectedColumns.forEach((val) => {
            columns[val] = val;
        });
        const selectedColumnsObject = columns;
        const unselectedColumns = this.array_diff(tableColumns, settings.selectedColumns);
        localStorage.setItem(tableAlias, JSON.stringify(unselectedColumns));
        return selectedColumnsObject;
    };

    array_diff(second: Array<any>, first: Array<any>) {
        return second.filter(function (i) {
            return first.indexOf(i) < 0;
        });
    }


    translatePlaceholders(directives: QueryList<PlaceholderTranslaterDirective>) {
        setTimeout(() => {
            for (let i = 0; i < directives.length; i++) {
                const element = directives['_results'][i].nativeElement;
                const self: any = jQuery(element.children[0]).data('select2');
                self.selection.placeholder = { id: '', text: this.translateService.instant(element.getAttribute('placeholder')) };
                self.dataAdapter.current(function (data) {
                    self.trigger('selection:update', {
                        data: data
                    });
                });
            }
        }, 100);
    }

    translateColumns(settings: ISettings): ISettings {
        settings.tableColumns = this.translateTableColumns(settings.tableColumns);
        return settings;
    }

    translateTableColumns(columns: Array<ITableColumn>): Array<ITableColumn> {
        const resultColumns = new Array<ITableColumn>();
        columns.forEach(element => {
            resultColumns.push(
                {
                    'trKey': element.trKey,
                    'lblKey': element.lblKey,
                    'name': this.translateService.instant(element.trKey),
                    'id': element.id,
                    'parentId': element.parentId,
                    'sortKey': element.sortKey
                }
            );
        });
        return resultColumns;
    }

    detectRtl() {
        const lang = this.cookieService.get('language');

        const rtlLanguages = ['ae', 'ar', 'arc', 'bcc', 'bqi', 'ckb', 'dv',
            'fa', 'glk', 'he', 'ku', 'mzn', 'nqo', 'pnb', 'ps', 'sd', 'ug', 'ur', 'yi'];

        return rtlLanguages.indexOf(lang) !== -1;
    }

    setFocusOnElement(element: ElementRef) {
        element.nativeElement.focus();
    }

    onMSelectClose() {
        setTimeout(() => {
            $('ss-multiselect-dropdown .dropdown button').attr('aria-expanded', 'false');
        }, 100);
    }

    onMSelectOpen() {
        setTimeout(() => {
            $('li.dropdown-item').each(function (index, elm) {
                $($(elm).find('a')).attr('tabindex', 0);
                $($(elm).find('a')).attr('role', 'button');
            });
            $('ss-multiselect-dropdown .dropdown button').attr('aria-expanded', 'true');
        }, 100);
        this.addAriaPressedMSelect();
    }

    addAriaMultiselect() {
        setTimeout(() => {
            $('ss-multiselect-dropdown .dropdown button').attr('aria-haspopup', 'true');
            $('ss-multiselect-dropdown .dropdown button').attr('aria-expanded', 'false');
        }, 100);
    }

    onMSelectModelChange(event, classState) {
        if (classState) {
            classState.state = (!(!event || !event.length));
        }
        this.addAriaPressedMSelect();
    }

    addAriaPressedMSelect() {
        setTimeout(() => {
            $('li.dropdown-item').each(function (index, elm) {
                if ($(elm).children().children().children().length > 0) {
                    $($(elm).find('a')).attr('aria-pressed', 'true');
                } else {
                    $($(elm).find('a')).attr('aria-pressed', 'false');
                }
            });
        }, 100);
    }

    getStatusPickList(dsaId: any, serviceName): Observable<any> {
        return this.httpClient.get(this.baseApi + this.commonEndPoints.statusPickList + `?dsaID=${dsaId}&serviceName=${serviceName}`)
            .pipe(
                tap(obj => obj.pickListValues.sort((a, b) => Number(a.sequence) - Number(b.sequence)))
            );
    }

    getPickListWithReplacedStatus(pickList: any, sortPickList) {
        const status = pickList.pickListItems.find(x => x.fieldName === sortPickList.fieldName);

        if (status) {
            status.pickListValues = sortPickList.pickListValues;
        } else {
            pickList.push(sortPickList);
        }
        return pickList;
    }

    scrollTableToBeginning() {
        const isRtl = document.body.classList.contains('rtl');

        if (this.mobileService.isMobile()) {
            $('html').animate({
                scrollTop: 0
            });
        }

        $('.search-table-wrap').animate({
            scrollLeft: isRtl ? $('.search-table-wrap').width() : '0px'
        });
    }

    private AddYear(date: Date, amount: number): Date {
        return new Date(new Date(date).setFullYear(date.getFullYear() + amount));
    }
}
