import 'rxjs/Rx';
import { ConfigService } from 'app/config/config.sevice';
import { RequestHelper } from 'app/core/http/request-helper';
import { HttpClient } from '@angular/common/http';
/* we need reuse the same countryCode therefore requesthelper was created for config.service*/
var HttpClientService = /** @class */ (function () {
    function HttpClientService(http, requestHelper, configService) {
        this.http = http;
        this.requestHelper = requestHelper;
        this.configService = configService;
    }
    HttpClientService.prototype.get = function (url, headers, anonymous) {
        var _this = this;
        if (anonymous === void 0) { anonymous = false; }
        headers = this.requestHelper.initHeaders(headers, anonymous);
        this.settingsHandler();
        return this.http.get(url, { headers: headers }).catch(function (error) { return _this.requestHelper.errorHandler(error); });
    };
    HttpClientService.prototype.post = function (url, parameters, headers, anonymous) {
        var _this = this;
        if (anonymous === void 0) { anonymous = false; }
        headers = this.requestHelper.initHeaders(headers, anonymous);
        this.settingsHandler();
        return this.http.post(url, parameters, { headers: headers }).catch(function (error) { return _this.requestHelper.errorHandler(error); });
    };
    ;
    HttpClientService.prototype.settingsHandler = function () {
        if (this.lastRequestDate && this.lastRequestDate < new Date().getTime() - 10000) {
            this.configService.loadConfig();
        }
        this.lastRequestDate = new Date().getTime();
    };
    return HttpClientService;
}());
export { HttpClientService };
