import {Injectable} from '@angular/core';
import {HttpClientService} from 'app/core/http/http-client.service';
import {Observable} from 'rxjs';
import {EnvironmentConfiguration} from 'app/config/evironment-config';

@Injectable()
export class ContainersTracingService {
    baseApi: any;
    apiEndPoint: any;

    constructor(private http: HttpClientService, private environmentConfig: EnvironmentConfiguration) {
        this.apiEndPoint = environmentConfig.get('commonEndPoints').tracing;
        this.baseApi = this.environmentConfig.get('baseApiUrl');
    }

    getContainerTracing(data: any): Observable<any> {
        return this.http.post(this.baseApi + this.apiEndPoint, data);
    }
}
