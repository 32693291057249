import { PipeTransform } from '@angular/core';
var CheckInvalidDate = /** @class */ (function () {
    function CheckInvalidDate() {
    }
    CheckInvalidDate.prototype.transform = function (value) {
        if (!value || value === 'Invalid date') {
            return '';
        }
        else {
            return value;
        }
    };
    return CheckInvalidDate;
}());
export { CheckInvalidDate };
