import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'checkInvalidDate'})
export class CheckInvalidDate implements PipeTransform {
    transform(value: string): string {
        if (!value || value === 'Invalid date') {
            return '';
        } else {
            return value;
        }
    }
}
