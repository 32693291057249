<script>
  System.config({
    packages: {
      app: {
        format: 'register',
        defaultExtension: 'js'
      }
    }
  });
  System.import('app/boot')
        .then(null, console.error.bind(console));
</script>

<a class="btn-user" role="button" tabindex="0" [attr.aria-pressed]="isAccessibilityMode" (click)="switchAccessibilityMode()">
    <span class="sr-only">{{'Labels.SrOnlyAccessibilityMode' | translate}}</span>
</a>
<div class="skip-links">
    <a [href]="skipMainLink" class="skip-main" >{{'Labels.SkipToMainContent' | translate}}</a>
</div>
<div class="wrapper" (click)="initBlur($event)">
  <router-outlet></router-outlet>
</div>

<div class="global-loader" *ngIf="loader || coverAllLoader" [ngClass]="{'cover-all': coverAllLoader}">
    <div class="loader common">
        <div class="se-pre-con"></div>
    </div>
</div>
