import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FollowOrderPipe } from '../../pipes/follow-order.pipe';
var PdfPopupManager = /** @class */ (function () {
    function PdfPopupManager(translateService) {
        this.translateService = translateService;
        this.popupStateChange = new Subject();
        this.followOrder = new FollowOrderPipe();
        this.tempOrder = ['PRINT_BL_ORIG',
            'PRINT_BL_ATTACHED_ORIG',
            'PRINT_BL_COPY_FRT',
            'PRINT_BL_ATTACHED_COPY_FRT',
            'PRINT_BL_COPY_UNFRT',
            'PRINT_BL_ATTACHED_COPY_UNFRT'];
    }
    PdfPopupManager.prototype.callPopup = function (files, options) {
        var _this = this;
        if (options === void 0) { options = '#toolbar=1&navpanes=1&scrollbar=1&zoom=100'; }
        files = this.setOrder(files);
        var items = files.map(function (data) {
            return {
                id: data.docName,
                text: _this.translateService.instant("PdfNames." + data.docType),
            };
        });
        this.popupStateChange.next({ files: items, options: options });
    };
    PdfPopupManager.prototype.setOrder = function (files) {
        return this.followOrder.transform(files, this.tempOrder, 'docType');
    };
    return PdfPopupManager;
}());
export { PdfPopupManager };
