import {NgModule} from '@angular/core';
import {ShowDirective} from './show.directive';

@NgModule({
    declarations: [
        ShowDirective
    ],
    exports: [
        ShowDirective
    ]
})
export class ShowModule {
}
