import { ElementRef, EventEmitter, OnDestroy, OnInit, Renderer, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { StorageDataReader } from '../../../shared/services/storage-data-reader.service';
import { NavbarService } from './navbar.service';
import { AuthService } from '../../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { ConfigService } from '../../../config/config.sevice';
import { Global } from 'app/shared/global';
import { interval } from 'rxjs';
import { MySettingsShareDataService } from '../../../shared/services/my-settings-share-data.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { RoutePermissionService } from '../../../core/permission/route-permissions.service';
import { StylesheetSwitcherService } from '../../../shared/services/stylesheet-switcher.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(renderer, renderer2, cookiesService, translate, el, router, authService, navbarService, localStor, global, mySettingsShareDataService, configService, mySettingsService, stylesheetSwitcherService, notificationsService, permissionsService) {
        this.renderer = renderer;
        this.renderer2 = renderer2;
        this.cookiesService = cookiesService;
        this.translate = translate;
        this.el = el;
        this.router = router;
        this.authService = authService;
        this.navbarService = navbarService;
        this.localStor = localStor;
        this.global = global;
        this.mySettingsShareDataService = mySettingsShareDataService;
        this.configService = configService;
        this.mySettingsService = mySettingsService;
        this.stylesheetSwitcherService = stylesheetSwitcherService;
        this.notificationsService = notificationsService;
        this.permissionsService = permissionsService;
        this.changeSidebarPosition = new EventEmitter();
        this.changeSidebarDisplay = new EventEmitter();
        this.openSidebar = new EventEmitter();
        this.display = 'Left';
        this.searchFormState = true;
        this.settings = {
            isOpen: false
        };
        this.fullName = '';
        this.showPersonal = false;
        this.showLogout = false;
        this.showMySettingsLink = false;
        this.showLanguageSelect = false;
        this.subPages = {};
        this.enableTopMenu = false;
        this.showPersonalInfo = false;
        this.userEmail = '';
        this.showNotificationsExistence = false;
        this.isNotificationsShowed = false;
        this.zimAuthData = this.localStor.getZimAuthData();
        this.enableTopMenu = configService.get('enableTopMenu');
        this.showMySettingsLink = configService.get('showMySettingsLink');
        this.isNotificationsShowed = permissionsService.getPermissions()['PUSHNTF'];
        this.setNotificationPoller();
        this.showLanguageSelect = configService.get('showLanguages');
        if (configService.get('languages')) {
            this.languages = configService.get('languages').map(function (lang) {
                return { id: lang.iso, text: lang.text };
            });
        }
        else {
            this.showLanguageSelect = false;
        }
    }
    NavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.hideLangSwitcher) {
            this.showLanguageSelect = false;
        }
        this.getZimAuthData();
        this.checkExistingAuthData();
        this.getMyProfileData();
        var lang = this.cookiesService.get('language') || 'en';
        if (this.showLanguageSelect) {
            if (this.cookiesService.get('language')) {
                this.translate.use(lang);
                this.initLanguage = this.translate.currentLang;
            }
            this.translate.use(lang).subscribe(function () {
                _this.initLanguage = _this.translate.currentLang;
            });
        }
        this.authService.getAuthState().subscribe(function (value) {
            if (value) {
                _this.generateFullName();
                _this.showPersonal = true;
            }
            else {
                _this.showPersonal = false;
            }
        });
        this.generateFullName();
        $('.user-block .btn-search, .search-form .close').click(function () {
            $('body').toggleClass('search-form-opened');
            return false;
        });
        $('.btn-menu').click(function () {
            if ($('body').hasClass('menu-opened') ||
                $('body').hasClass('contact-opened') ||
                $('body').hasClass('location-opened') ||
                $('body').hasClass('side-menu-opened')) {
                $('body').removeClass('menu-opened contact-opened location-opened side-menu-opened');
            }
            else {
                $('body').addClass('side-menu-opened');
            }
            return false;
        });
        if (this.configService.get('enableTopMenu')) {
            this.getSubPages();
        }
        this.mySettingsDataSubscription = this.mySettingsShareDataService.getData().subscribe(function (data) {
            if (data && data.firstName) {
                _this.fullName = data.firstName.trim() + ' ' + data.lastName;
                _this.myProfileData = data;
                _this.initLanguage = data.preferredLang;
            }
        });
        this.notificationsService.getResetState().subscribe(function (state) {
            _this.showNotificationsExistence = !state;
        });
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        this.mySettingsDataSubscription.unsubscribe();
    };
    NavbarComponent.prototype.languageChanged = function (lang) {
        this.cookiesService.put('language', lang);
        this.translate.use(lang);
        this.global.currentLanguage = lang;
        this.stylesheetSwitcherService.switchStylesheet(lang);
        if (this.initLanguage !== lang) {
            this.sendLanguage(lang);
        }
        this.initLanguage = lang;
        $('body').removeClass('location-opened');
    };
    NavbarComponent.prototype.getZimAuthData = function () {
        var retrieveData = sessionStorage.getItem('zim_auth_data');
        this.zimAuthData = JSON.parse(retrieveData);
    };
    NavbarComponent.prototype.generateFullName = function () {
        var data = this.zimAuthData;
        if (data && data.contact) {
            var adminString = /\(Admin\)/g;
            var lastName = data.contact.lastName.replace(adminString, '').trim();
            this.fullName = data.contact.firstName + ' ' + lastName;
            this.userEmail = data.contact.userName;
        }
    };
    NavbarComponent.prototype.checkExistingAuthData = function () {
        var token = this.cookiesService.get('zim_access_token');
        if (token) {
            this.showLogout = true;
        }
        if (this.zimAuthData) {
            this.showPersonal = true;
        }
    };
    NavbarComponent.prototype.onLogout = function () {
        this.authService.logout();
    };
    NavbarComponent.prototype.sidebarPosition = function (position) {
        this.changeSidebarPosition.emit(position);
    };
    NavbarComponent.prototype.sidebarDisplay = function (position) {
        this.changeSidebarDisplay.emit(position);
    };
    NavbarComponent.prototype.sidebarOpen = function () {
        this.openSidebar.emit();
    };
    NavbarComponent.prototype.searchFormOpen = function () {
        if (this.searchFormState) {
            this.changeStyleElement('#search-form', 'height', '40px');
            this.changeStyleElement('.notifications ', 'top', '86px');
        }
        else {
            this.changeStyleElement('#search-form', 'height', '0px');
            this.changeStyleElement('.notifications ', 'top', '46px');
        }
        this.searchFormState = !this.searchFormState;
    };
    NavbarComponent.prototype.getSubPages = function () {
        var _this = this;
        this.navbarService.getSubPagesData().subscribe(function (data) {
            _this.subPages = data;
            _this.onOpenList();
        });
    };
    NavbarComponent.prototype.onOpenList = function () {
        setTimeout(function () {
            $('.mobile-nav .drop-menu').parent('li').addClass('has-drop');
            $('.mobile-nav .has-drop > a').append('<span class="opener"><span>Open Drop</span></span>');
            $('.mobile-nav .has-drop').click(function () {
                var parent_ = $(this).closest('li');
                if (parent_.hasClass('active')) {
                    parent_.removeClass('active').children('.drop-menu').slideUp(300);
                }
                else {
                    parent_.addClass('active').children('.drop-menu').slideDown(300);
                    parent_.siblings('.active').removeClass('active').children('.drop-menu').slideUp(300);
                }
                return false;
            });
        }, 300);
    };
    NavbarComponent.prototype.onDashboardNavigate = function () {
        this.router.navigateByUrl('app/dashboard');
    };
    NavbarComponent.prototype.onMySettingsNavigate = function () {
        this.router.navigateByUrl('app/my-settings');
    };
    NavbarComponent.prototype.onNotificationSettingsNavigate = function () {
        this.router.navigateByUrl('app/notifications');
    };
    NavbarComponent.prototype.onShowPersonalInfoToggle = function () {
        this.showPersonalInfo = !this.showPersonalInfo;
    };
    NavbarComponent.prototype.changeStyleElement = function (selector, styleName, styleValue) {
        this.renderer.setElementStyle(this.el.nativeElement
            .querySelector(selector), styleName, styleValue);
    };
    NavbarComponent.prototype.setNotificationPoller = function () {
        var _this = this;
        this.notificationPollerInterval = this.configService.get('notificationsCheckInterval') || 5;
        if (this.notificationPollerInterval > 0 && this.isNotificationsShowed) {
            this.getNotificationsCounter();
            var parsedInterval = this.notificationPollerInterval * 60000;
            this.notificationPoller = interval(parsedInterval).map(function () {
                _this.getNotificationsCounter();
            });
            this.notificationPoller.subscribe();
        }
    };
    NavbarComponent.prototype.getNotificationsCounter = function () {
        var _this = this;
        if (this.zimAuthData) {
            var dsaId = this.zimAuthData.dsaData.dsaId;
            this.notificationsService.getNewNotificationsCounter(dsaId).subscribe(function (response) {
                _this.showNotificationsExistence = response.numberOfNewNotifications > 0;
            });
        }
    };
    NavbarComponent.prototype.sendLanguage = function (language) {
        var request = this.myProfileData;
        request.preferredLang = language;
        this.mySettingsShareDataService.setNavLanguage(language);
        this.mySettingsService.setMyUserProfile(request).subscribe();
    };
    NavbarComponent.prototype.getMyProfileData = function () {
        var _this = this;
        if (!this.zimAuthData) {
            return;
        }
        var request = {
            'dsaID': this.zimAuthData.dsaData.dsaId
        };
        this.mySettingsService.getMyUserProfile(request).subscribe(function (response) {
            _this.myProfileData = {
                dsaID: _this.zimAuthData.dsaData.dsaId,
                firstName: response.firstName,
                lastName: response.lastName,
                preferredLang: response.language,
                timeZone: response.timeZone,
                mobileNo: response.mobileNo,
                myAdminZim: response.myAdminZim,
                myAdminZimEmail: response.myAdminZimEmail
            };
            _this.mySettingsShareDataService.sendData(_this.myProfileData);
        });
    };
    NavbarComponent.prototype.closePersonalInfo = function () {
        this.showPersonalInfo = false;
    };
    return NavbarComponent;
}());
export { NavbarComponent };
