import { CookieService } from 'ngx-cookie';
import '../../../assets/js/style-config.js';
import * as moment from 'moment';
var StylesheetSwitcherService = /** @class */ (function () {
    function StylesheetSwitcherService(cookieService) {
        this.cookieService = cookieService;
    }
    StylesheetSwitcherService.prototype.switchStylesheet = function (lang) {
        if (!lang) {
            lang = this.cookieService.get('language');
        }
        moment.locale(lang);
        var rtlLanguages = ['ae', 'ar', 'arc', 'bcc', 'bqi', 'ckb', 'dv',
            'fa', 'glk', 'he', 'ku', 'mzn', 'nqo', 'pnb', 'ps', 'sd', 'ug', 'ur', 'yi'];
        if (rtlLanguages.indexOf(lang) === -1) {
            document.getElementById('stylesheet-switcher')['href'] = stylesNames['ltr'];
            document.getElementsByTagName('body')[0].classList.remove('rtl');
        }
        else {
            document.getElementById('stylesheet-switcher')['href'] = stylesNames['rtl'];
            document.getElementsByTagName('body')[0].classList.add('rtl');
        }
    };
    return StylesheetSwitcherService;
}());
export { StylesheetSwitcherService };
