import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from 'app/auth/auth.service';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {StorageDataReader} from 'app/shared/services/storage-data-reader.service';
import {CookieService} from 'ngx-cookie';

@Injectable()
export class AuthGuardService implements CanActivate {
    lastPath: string;

    constructor(private authService: AuthService, private router: RouterWrapper, private dataReader: StorageDataReader,
                private cookiesService: CookieService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        const authenticated = this.authService.isAuthentificated();

        if (!authenticated) {
            this.cookiesService.put('zim_redirect_url', state.url);
            this.router.toLogin();
            return false;
        }

        const userData = this.dataReader.getZimAuthData();

        if (!userData) {
            return new Promise<boolean>((resolve) => {
                this.authService.getAuthData().subscribe(data => {
                    this.authService.setAuthData(data);
                    resolve(true);
                }, error => {

                    if (error.status === 401) {
                        this.router.toLogin();
                        return;
                    }

                    resolve(true);
                });
            });
        }

        this.lastPath = state.url;

        return authenticated;
    }
}
