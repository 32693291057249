import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {RoutePermissionService} from 'app/core/permission/route-permissions.service';

@Injectable()
export class PermissionsGuard implements CanActivate {

    constructor(private router: RouterWrapper, private routePermission: RoutePermissionService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.routePermission.allowedForCustomer(route.routeConfig.data.name);
    }
}
