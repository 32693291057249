import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {tap} from 'rxjs/operators';
import {RouterWrapper} from '../routing/router.wrapper';
import {ScreenGeneral} from '../../shared/utils/screen-general';
import {ErrorHandlerService} from '../../shared/services/error-handler.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private routerWrapper: RouterWrapper, private screenGeneral: ScreenGeneral,
                private errorHandlerService: ErrorHandlerService) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(req).pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                    }
                }, (error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        switch (error.status) {
                            case 400:
                                this.errorHandlerService.handleErrorByCode(error.error);
                                break;
                            case 401:
                                this.routerWrapper.toLogin();
                                break;
                            case 0:
                                this.errorHandlerService.handleUnknownError(error, req.url);
                                break;

                        }
                    }
                    return observableThrowError(error);
                }))
    }
}
