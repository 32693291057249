import {Router} from '@angular/router';
import {Injectable, Injector} from '@angular/core';
import {EnvironmentConfiguration} from 'app/config/evironment-config';
import {CookieService} from 'ngx-cookie';

@Injectable()
export class RouterWrapper {
    apiEndPoint: any;

    public get router(): Router {
        return this.injector.get(Router);
    }

    constructor(
        private injector: Injector,
        private environmentConfiguration: EnvironmentConfiguration,
        private cookieService: CookieService) {
        this.apiEndPoint = environmentConfiguration.get('signIn');
    }

    toHome() {
        this.router.navigate(['/app']);
    }

    toLogin() {
        location.href = this.setUILanguage(this.apiEndPoint.loginLink);
    }

    toLogout() {
        location.href = this.setUILanguage(this.apiEndPoint.logoutLink);
    }

    toDashboard() {
        this.router.navigate(['/app/dashboard']);
    }

    toErrorPage(msg: {text?: string, translationKey?: string, params?: object} = {translationKey : 'Errors.General.SystemUnderMaintenance'},
                isTryAgain = false) {
        const queryParams = {
            msg: JSON.stringify(msg),
            isTryAgain: isTryAgain
        };
        this.router.navigate(['/maintance-error'], {
            queryParams: queryParams});

    }

    toMaintancePage() {
        this.router.navigate(['/maintance-error']);
    }

    private setUILanguage(url: string): string {

        const pattern = new RegExp('\\b(ui_locales=).*?(&|$)');

        const language = this.cookieService.get('language') || 'en';

        if (url.search(pattern) >= 0) {
            return url.replace(pattern, '$1' + language + '$2');
        }

        url = url.replace(/\?$/, '');

        return url + (url.indexOf('?') > 0 ? '&' : '?') + 'ui_locales' + '=' + language;
    }

    toPage(url: string) {
        this.router.navigate([url]);
    }
}
