import { HttpClientService } from 'app/core/http/http-client.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { CookieService } from 'ngx-cookie';
import { Subject, Observable } from 'rxjs';
import { JwtHelper } from 'app/shared/helpers/JWTParser';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
var AuthService = /** @class */ (function () {
    function AuthService(httpClient, environmentConfig, router, cookieService) {
        this.httpClient = httpClient;
        this.environmentConfig = environmentConfig;
        this.router = router;
        this.cookieService = cookieService;
        this.authState = new Subject();
        this.jwtHelper = new JwtHelper();
        this.baseApi = environmentConfig.get('baseApiUrl');
    }
    AuthService.prototype.getAuthState = function () {
        return this.authState;
    };
    AuthService.prototype.setAuthState = function (auth) {
        this.authState.next(auth);
    };
    AuthService.prototype.isAuthentificated = function () {
        return !!this.cookieService.get('zim_access_token');
    };
    AuthService.prototype.getAuthData = function () {
        var validDsa = this.checkTokenDsa(this.cookieService.get('zim_access_token'));
        if (!validDsa) {
            return Observable.empty();
        }
        return this.httpClient.get(this.baseApi + this.environmentConfig.get('getmycontactEndPoint').contacts);
    };
    AuthService.prototype.setAuthData = function (data) {
        sessionStorage.setItem('zim_auth_data', JSON.stringify(data));
        this.setAuthState(true);
    };
    AuthService.prototype.removeAuthData = function () {
        sessionStorage.removeItem('zim_auth_data');
        this.setAuthState(false);
    };
    AuthService.prototype.checkTokenDsa = function (token) {
        var parsedObj = this.jwtHelper.decodeToken(token);
        return parsedObj && parsedObj['extension_DSAContactId'] !== '';
    };
    AuthService.prototype.logout = function () {
        this.clearAuthorizationData();
        this.router.toLogout();
    };
    AuthService.prototype.clearAuthorizationData = function () {
        this.cookieService.remove('zim_access_token');
        this.cookieService.remove('refresh_token');
        this.cookieService.remove('zim_customer_services');
        this.cookieService.remove('expires_in');
        this.removeAuthData();
    };
    return AuthService;
}());
export { AuthService };
