import { HttpClientService } from '../../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../../config/evironment-config';
var NavbarService = /** @class */ (function () {
    function NavbarService(http, environmentConfig, httpClient) {
        this.http = http;
        this.environmentConfig = environmentConfig;
        this.httpClient = httpClient;
        this.apiEndPoint = environmentConfig.get('externalEndPoints').topNavigationEndPoint;
        this.baseApi = environmentConfig.get('baseApiUrl');
        this.commonEndPoint = environmentConfig.get('commonEndPoints');
    }
    NavbarService.prototype.getSubPagesData = function () {
        return this.http.get(this.apiEndPoint);
    };
    NavbarService.prototype.sendLanguage = function (request) {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.setMyLanguage, request);
    };
    return NavbarService;
}());
export { NavbarService };
