import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs/Rx';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class MySettingsShareDataService {
    public subject = new BehaviorSubject({});
    public navLanguageSubject = new Subject<any>();

    sendData(data: object) {
        this.subject.next(data);
    }

    getData(): Observable<any> {
        return this.subject.asObservable();
    }

    setNavLanguage(data: string) {
        this.navLanguageSubject.next(data);
    }

    getNavLanguage(): Observable<any> {
        return this.navLanguageSubject.asObservable();
    }
}
