import {Injectable} from '@angular/core';
import {MessageModalService} from '../components/message-modal/message-modal.service';
import {TranslateService} from '@ngx-translate/core';
import {RouterWrapper} from '../../core/routing/router.wrapper';
import {AuthService} from '../../auth/auth.service';
import {ScreenGeneral} from '../utils/screen-general';


@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    private specificErrorsByCode = {
        'CA-0002': {
            title: 'inactive-user',
            handle: () => this.handleInactiveUserError()
        },
        'CA-0020': {
            handle: () => {
            }
        },
        'CA-0021': {
            handle: () => {
            }
        }
    };

    private endpointsToSkip = [ '/getbanners', '/setbannerview', '/setbannerstopshow', '/setbannerapproval'];

    constructor(private messageModalService: MessageModalService,
                private translateService: TranslateService,
                private routerWrapper: RouterWrapper,
                private authService: AuthService,
                private screenGeneral: ScreenGeneral) {
    }

    handleErrorByCode(error: { returnCode: string, returnMessage: string }) {
        this.specificErrorsByCode[error.returnCode] ?
            this.handleSpecificErrorByCode(error) : this.handleGeneralErrorByCode(error);
    }

    handleInactiveUserError() {
        this.authService.clearAuthorizationData();
        this.routerWrapper
            .toErrorPage({text: 'Your account is currently inactive. Please contact your local administrator.', params: {}}, true);
    }

    handleUnknownError(error: any, url: string) {
        if (!this.isEndpointToSkip(url)) {
            this.screenGeneral.screenGeneralError();
        }
    }

    private isEndpointToSkip(url: string): boolean {
        for (const endpoint of this.endpointsToSkip) {
            if (url.includes(endpoint)) {
                return true;
            }
        }

        return false;
    }

    private handleGeneralErrorByCode(error: { returnCode: string, returnMessage: string }) {
        const desc = error.returnMessage.split('.')[0].split(' ').map(v => this.capitalizeFirstLetter(v)).join('').slice(0, 20);
        const key =
            `Errors.ByCode.${desc}_${error.returnCode}`;
        this.messageModalService.callModal(this.translateService.instant(key),
            this.translateService.instant('Errors.General.GeneralTitle'));
    }

    private handleSpecificErrorByCode(error: { returnCode: string, returnMessage: string }) {
        this.specificErrorsByCode[error.returnCode].handle(error);
    }

    private capitalizeFirstLetter(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}
