import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {TranslateLoader} from '@ngx-translate/core';
import {HttpClientService} from 'app/core/http/http-client.service';
import {EnvironmentConfiguration} from 'app/config/evironment-config';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {RouterTestingModule} from '@angular/router/testing';
import {RouterWrapper} from '../../core/routing/router.wrapper';

@Injectable()
export class LocalizationService implements TranslateLoader {
    baseApi: any;
    apiEndPoint: any;

    constructor(private http: HttpClientService, private environmentConfig: EnvironmentConfiguration,
                private routerWrapper: RouterWrapper) {
        this.apiEndPoint = environmentConfig.get('umbraco').translates;
        this.baseApi = this.environmentConfig.get('baseApiUrl');
    }

    getTranslation(lang: string): Observable<any> {
        return this.http.get(this.baseApi + this.apiEndPoint + '/' + lang).pipe(catchError(error => {
            this.routerWrapper.toErrorPage();
            return of(error);
        }));
    }
}
