import {Injectable} from '@angular/core';

@Injectable()
export class StorageDataReader {

    constructor() {
    }

    getZimAuthData(): any {
        return JSON.parse(sessionStorage.getItem('zim_auth_data') || null);
    }
}
