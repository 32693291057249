import { AuthService } from 'app/auth/auth.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { StorageDataReader } from 'app/shared/services/storage-data-reader.service';
import { CookieService } from 'ngx-cookie';
import { ConfigService } from 'app/config/config.sevice';
var UmbracoConfigGuard = /** @class */ (function () {
    function UmbracoConfigGuard(authService, router, dataReader, cookiesService, configService) {
        this.authService = authService;
        this.router = router;
        this.dataReader = dataReader;
        this.cookiesService = cookiesService;
        this.configService = configService;
    }
    UmbracoConfigGuard.prototype.canActivate = function (route, state) {
        var token = this.cookiesService.get('zim_access_token');
        if (token) {
            return this.configService.loadConfig();
        }
        return true;
    };
    return UmbracoConfigGuard;
}());
export { UmbracoConfigGuard };
