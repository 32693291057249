import { AuthService } from 'app/auth/auth.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { StorageDataReader } from 'app/shared/services/storage-data-reader.service';
import { CookieService } from 'ngx-cookie';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService, router, dataReader, cookiesService) {
        this.authService = authService;
        this.router = router;
        this.dataReader = dataReader;
        this.cookiesService = cookiesService;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        var authenticated = this.authService.isAuthentificated();
        if (!authenticated) {
            this.cookiesService.put('zim_redirect_url', state.url);
            this.router.toLogin();
            return false;
        }
        var userData = this.dataReader.getZimAuthData();
        if (!userData) {
            return new Promise(function (resolve) {
                _this.authService.getAuthData().subscribe(function (data) {
                    _this.authService.setAuthData(data);
                    resolve(true);
                }, function (error) {
                    if (error.status === 401) {
                        _this.router.toLogin();
                        return;
                    }
                    resolve(true);
                });
            });
        }
        this.lastPath = state.url;
        return authenticated;
    };
    return AuthGuardService;
}());
export { AuthGuardService };
