import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {ConfigService} from 'app/config/config.sevice';
import {AuthService} from 'app/auth/auth.service';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from '../../../shared/services/loader.service';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'maintance-error',
    templateUrl: './maintance-error.html',
    styleUrls: ['./maintance-error.scss']
})
export class MaintanceErrorComponent implements OnInit {

    isDefaultLanguage = false;
    msg: {translationKey: string, text: string, params: any};
    isTryAgain = false;
    constructor(private configService: ConfigService,
                private cookiesService: CookieService,
                private translateService: TranslateService,
                private authService: AuthService,
                private router: RouterWrapper,
                private loaderService: LoaderService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(queryParams => {
            const lang = this.cookiesService.get('language') || 'en';
            this.msg = JSON.parse(queryParams.get('msg'));
            this.isTryAgain = queryParams.get('isTryAgain') === 'true';
            if ( this.authService.isAuthentificated()) {
                this.translateService.use(lang).subscribe(
                    (data) => {
                        this.isDefaultLanguage = data instanceof HttpErrorResponse;
                        this.loaderService.hideCoverAllSpinner();
                    },
                    () => {
                        this.isDefaultLanguage = true;
                        this.loaderService.hideCoverAllSpinner();
                    }
                );
            } else {
                setTimeout(() => this.loaderService.hideCoverAllSpinner(), 1000 );
                this.isDefaultLanguage = true;
            }
        })
    }

    tryAgain() {
        this.router.toLogin();
    }
}
