import { StorageDataReader } from '../../shared/services/storage-data-reader.service';
import { Subject } from 'rxjs';
import { PermissionsMap } from 'app/core/permission/app.permissions-map.config';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
var RoutePermissionService = /** @class */ (function () {
    function RoutePermissionService(permissionsMap, routerWrapper, localStor) {
        this.permissionsMap = permissionsMap;
        this.routerWrapper = routerWrapper;
        this.localStor = localStor;
        this.allowedMenuItems = new Subject();
    }
    RoutePermissionService.prototype.allowedForCustomer = function (routeName) {
        var _this = this;
        this.userData = this.localStor.getZimAuthData();
        var data = this.userData || { contact: { services: [] } };
        data.contact.services = data.contact.services || [];
        var allowed = data.contact.services
            .find(function (x) { return x.serviceName === _this.permissionsMap.customerRoutePermissionMap[routeName] && x.isEnabled; }) ? true : false;
        return allowed;
    };
    RoutePermissionService.prototype.checkAllowedMenuItems = function () {
        this.userData = this.localStor.getZimAuthData();
        var data = this.userData || { contact: { services: [] } };
        data.contact.services = data.contact.services || [];
        var permissions = {};
        for (var i = 0; i < data.contact.services.length; i++) {
            permissions[data.contact.services[i].serviceName] = data.contact.services[i].isEnabled;
        }
        this.allowedMenuItems.next(permissions);
    };
    RoutePermissionService.prototype.getPermissions = function () {
        this.userData = this.localStor.getZimAuthData();
        var data = this.userData || { contact: { services: [] } };
        data.contact.services = data.contact.services || [];
        var permissions = {};
        for (var i = 0; i < data.contact.services.length; i++) {
            permissions[data.contact.services[i].serviceName] = data.contact.services[i].isEnabled;
        }
        return permissions;
    };
    return RoutePermissionService;
}());
export { RoutePermissionService };
