import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../../../config/config.sevice';
import { parse } from 'libphonenumber-js';
var MobileNumberService = /** @class */ (function () {
    function MobileNumberService(configService) {
        this.configService = configService;
        this.blockedCountries = this.configService.get('blockedCountries');
    }
    MobileNumberService.prototype.getCountryPhoneCodes = function () {
        return new Observable(function (observer) {
            var countryPhoneCodesData = require('assets/data/country-phone-codes.json');
            var mapCountryPhoneCodes = countryPhoneCodesData.countries
                .map(function (country) { return ({ id: country.dial_code, text: country.dial_code }); })
                .sort(function (first, second) { return first.id - second.id; })
                .filter(function (item, index, self) {
                return index === self.findIndex(function (t) { return (t.id === item.id && t.text === item.text); });
            });
            observer.next(mapCountryPhoneCodes);
            observer.complete();
        });
    };
    MobileNumberService.prototype.isCountryBlocked = function (phoneNumber) {
        if (!phoneNumber) {
            return false;
        }
        var parsedCountry = parse(phoneNumber);
        return this.blockedCountries && this.blockedCountries.some(function (country) { return country.code === parsedCountry.country; });
    };
    return MobileNumberService;
}());
export { MobileNumberService };
