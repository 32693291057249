import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FollowOrder'
})
export class FollowOrderPipe implements PipeTransform {

    transform(array: Array<any>, followOrderArr: Array<string>, path: string) {
        if (!array || !path) {
            return array;
        }
        const copyFollowOrderArr = [];
        copyFollowOrderArr.push(...followOrderArr);
        copyFollowOrderArr.reverse().forEach(value => {
            const index =
                array.findIndex(this.findIndexPredicate.bind({path: path, value: value}));
            if (index !== -1) {
                const temp = array[index];
                array.splice(index, 1);
                array.unshift(temp);
            }
        });

        return array;
    }

    findIndexPredicate(element) {
        const pathTree = this['path'].split('.');
        pathTree.forEach(property => {
            element = element[property];
        });
        element = element.split(' ');
        const value = this['value'].split(' ');
        return element[0] === value[0];
    }
}
