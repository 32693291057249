import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from 'app/auth/auth.service';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {StorageDataReader} from 'app/shared/services/storage-data-reader.service';
import {CookieService} from 'ngx-cookie';
import {ConfigService} from 'app/config/config.sevice';

@Injectable()
export class UmbracoConfigGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: RouterWrapper,
                private dataReader: StorageDataReader,
                private cookiesService: CookieService,
                private configService: ConfigService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const token = this.cookiesService.get('zim_access_token');

        if (token) {
            return this.configService.loadConfig();
        }

        return true;
    }
}
