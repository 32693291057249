import {Injectable} from '@angular/core';
import {HttpClientService} from '../../core/http/http-client.service';
import {EnvironmentConfiguration} from '../../config/evironment-config';
import {Observable} from 'rxjs';
declare var require: any;

@Injectable()

export class MySettingsService {
    baseApi: any;
    commonEndPoint: any;
    countryPhoneCodesData: any;

    constructor(private httpClient: HttpClientService,
                private environmentConfig: EnvironmentConfiguration) {
        this.baseApi = environmentConfig.get('baseApiUrl');
        this.commonEndPoint = environmentConfig.get('commonEndPoints');
        this.countryPhoneCodesData = require('assets/data/country-phone-codes.json');
    }

    getMyUserProfile(request) {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.getMyUserProfile, request);
    }

    setMyUserProfile(request) {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.setMyUserProfile, request);
    }

    getCountryPhoneCodes(): Observable<any> {
        return new Observable<any>(observer => {
            observer.next(this.countryPhoneCodesData);
            observer.complete();
        })
    }

    getTimeZonePicklist(request) {
        return this.httpClient.post(this.baseApi + this.commonEndPoint.getTimeZonePicklist, request);
    }
}
