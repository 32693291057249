import { ElementRef, Renderer2 } from '@angular/core';
var NavBarOpenDirective = /** @class */ (function () {
    function NavBarOpenDirective(el, renderer) {
        this.el = el;
        this.renderer = renderer;
    }
    NavBarOpenDirective.prototype.handleClick = function () {
        var parent = this.el.nativeElement.parentNode;
        if (parent.classList.contains('open')) {
            this.renderer.removeClass(parent, 'open');
        }
        else {
            for (var i = 0; i < this.listHandler.children.length; i++) {
                this.renderer.removeClass(this.listHandler.children[i], 'open');
            }
            this.renderer.addClass(parent, 'open');
        }
    };
    return NavBarOpenDirective;
}());
export { NavBarOpenDirective };
