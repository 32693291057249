import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthComponent} from 'app/auth/azure-auth/auth.component';
import {UmbracoConfigGuard} from 'app/auth/umbraco-config-guard';
import {MaintanceErrorComponent} from 'app/containers/error-page/maintance-error/maintance-error';

const appRoutes: Routes = [
    {path: '', redirectTo: 'app', pathMatch: 'full'},
    {path: 'app', loadChildren: './containers/layout/layout.module#LayoutModule', canActivate: [UmbracoConfigGuard]},
    {path: 'auth', component: AuthComponent, pathMatch: 'full'},
    {path: 'maintance-error', component: MaintanceErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {useHash: false})],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
