import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[navBarOpen]'
})

export class NavBarOpenDirective {
    @Input() listHandler;

    constructor(public el: ElementRef, public renderer: Renderer2) {
    }

    @HostListener('click') handleClick() {
        const parent = this.el.nativeElement.parentNode;
        if (parent.classList.contains('open')) {
            this.renderer.removeClass(parent, 'open');
        } else {
            for (let i = 0; i < this.listHandler.children.length; i++) {
                this.renderer.removeClass(this.listHandler.children[i], 'open');
            }
            this.renderer.addClass(parent, 'open');
        }
    }
}
