import {Injectable} from '@angular/core';
import IDateRange from '../../shared/interfaces/date-range.interface';
import {FormGroup} from '@angular/forms';
import {ConfigService} from '../../config/config.sevice';

@Injectable()
export class DateRangeService {

    constructor(
        private configService: ConfigService
    ) {
    }

    validateFn(control: FormGroup) {
        control.controls.fromDate.setErrors(null);
        control.controls.toDate.setErrors(null);
        const dateRange: IDateRange = {fromDate: control.value.fromDate, toDate: control.value.toDate};
        this.validate(dateRange);
        if (dateRange.fromDateError) {
            control.controls.fromDate.setErrors({'validationError': dateRange.fromDateError});
        }
        if (dateRange.toDateError) {
            control.controls.toDate.setErrors({'validationError': dateRange.toDateError});
        }
        return null;
    }

    validate(dateRange: IDateRange): boolean {

        dateRange.fromDateError = null;
        dateRange.toDateError = null;
        if (dateRange.fromDate && dateRange.toDate) {
            if (dateRange.fromDate > dateRange.toDate) {
                dateRange.fromDateError = 'Errors.Date.FromDateIsGreaterThenTo';
            }
        }

        return !dateRange.fromDateError && !dateRange.toDateError;
    }

    init(container: string): IDateRange {
        return {
            toDate: this.initDate(container, 'to'),
            fromDate: this.initDate(container, 'from'),
            toDateError: null,
            fromDateError: null,
        };
    }

    private initDate(container: string, direction: string): Date {
        let date = new Date();
        switch (container) {
            case 'empty':
                switch (direction) {
                    case 'to':
                        date = null;
                        break;
                    case 'from':
                        date = null;
                        break;
                }
                break;
            case 'export':
                switch (direction) {
                    case 'to':
                        if (!this.configService.get('exportFilterToDays') || this.configService.get('exportFilterToDays') === 0) {
                            date = null;
                        } else {
                            date.setDate(new Date().getDate() + this.configService.get('exportFilterToDays'));
                        }
                        break;
                    case 'from':
                        if (!this.configService.get('exportFilterFromDays') || this.configService.get('exportFilterFromDays') === 0) {
                            date = null;
                        } else {
                            date.setDate(new Date().getDate() - this.configService.get('exportFilterFromDays'));
                        }
                        break;
                }
                break;
            case 'import':
                switch (direction) {
                    case 'to':
                        if (!this.configService.get('importFilterToDays') || this.configService.get('importFilterToDays') === 0) {
                            date = null;
                        } else {
                            date.setDate(new Date().getDate() + this.configService.get('importFilterToDays'));
                        }
                        break;
                    case 'from':
                        if (!this.configService.get('importFilterFromDays') || this.configService.get('importFilterFromDays') === 0) {
                            date = null;
                        } else {
                            date.setDate(new Date().getDate() - this.configService.get('importFilterFromDays'));
                        }
                        break;
                }
                break;
            case 'finance':
                switch (direction) {
                    case 'to':
                        if (!this.configService.get('financeFilterToDays') || this.configService.get('financeFilterToDays') === 0) {
                            date = null;
                        } else {
                            date.setDate(new Date().getDate() + this.configService.get('financeFilterToDays'));
                        }
                        break;
                    case 'from':
                        if (!this.configService.get('financeFilterFromDays') || this.configService.get('financeFilterFromDays') === 0) {
                            date = null;
                        } else {
                            date.setDate(new Date().getDate() - this.configService.get('financeFilterFromDays'));
                        }
                        break;
                }
                break;
        }
        return date;
    }
}
