import {NgModule} from '@angular/core';
import {ShowInvalidSearchDirective} from 'app/shared/directives/show-invalid-search/show-invalid-search.directive';

@NgModule({
    declarations: [ShowInvalidSearchDirective],
    exports: [ShowInvalidSearchDirective]
})

export class ShowInvalidSearchModule {
}
