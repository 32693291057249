import { CookieService } from 'ngx-cookie';
var AccessibilityService = /** @class */ (function () {
    function AccessibilityService(cookieService) {
        this.cookieService = cookieService;
    }
    AccessibilityService.prototype.enableAccessibilityMode = function () {
        var element = document.getElementsByTagName('body')[0];
        element.classList.add('accessibility');
    };
    AccessibilityService.prototype.disableAccessibilityMode = function () {
        var element = document.getElementsByTagName('body')[0];
        if (element.classList.contains('accessibility')) {
            element.classList.remove('accessibility');
        }
    };
    AccessibilityService.prototype.updateLangOnHtml = function (lang) {
        if (!lang) {
            lang = this.cookieService.get('language');
        }
        var html = document.getElementsByTagName('html')[0];
        html.lang = lang;
    };
    return AccessibilityService;
}());
export { AccessibilityService };
