import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RouterWrapper } from '../routing/router.wrapper';
import { ScreenGeneral } from '../../shared/utils/screen-general';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
var ErrorHandlerInterceptor = /** @class */ (function () {
    function ErrorHandlerInterceptor(routerWrapper, screenGeneral, errorHandlerService) {
        this.routerWrapper = routerWrapper;
        this.screenGeneral = screenGeneral;
        this.errorHandlerService = errorHandlerService;
    }
    ErrorHandlerInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next
            .handle(req).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
            }
        }, function (error) {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 400:
                        _this.errorHandlerService.handleErrorByCode(error.error);
                        break;
                    case 401:
                        _this.routerWrapper.toLogin();
                        break;
                    case 0:
                        _this.errorHandlerService.handleUnknownError(error, req.url);
                        break;
                }
            }
            return observableThrowError(error);
        }));
    };
    return ErrorHandlerInterceptor;
}());
export { ErrorHandlerInterceptor };
