import {NavBarOpenDirective} from './navbar-open.directive';
import {NgModule} from '@angular/core';

@NgModule({
    declarations: [
        NavBarOpenDirective
    ],
    exports: [
        NavBarOpenDirective
    ]
})
export class NavBarOpenModule {
}
