import { PipeTransform } from '@angular/core';
var DashfixPipe = /** @class */ (function () {
    function DashfixPipe() {
    }
    DashfixPipe.prototype.transform = function (value) {
        if (value) {
            return value + '-';
        }
    };
    return DashfixPipe;
}());
export { DashfixPipe };
