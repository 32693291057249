import { PipeTransform } from '@angular/core';
var BracketsPostfixPipe = /** @class */ (function () {
    function BracketsPostfixPipe() {
    }
    BracketsPostfixPipe.prototype.transform = function (value) {
        if (value) {
            return '(' + value + ')' + '/';
        }
    };
    return BracketsPostfixPipe;
}());
export { BracketsPostfixPipe };
