import { PipeTransform } from '@angular/core';
var PostfixPipe = /** @class */ (function () {
    function PostfixPipe() {
    }
    PostfixPipe.prototype.transform = function (value) {
        if (value) {
            return value + '/';
        }
    };
    return PostfixPipe;
}());
export { PostfixPipe };
