import * as FileSaver from 'file-saver';
var FileService = /** @class */ (function () {
    function FileService() {
    }
    FileService.prototype.saveFile = function (data, fileName, toBase64) {
        if (toBase64 === void 0) { toBase64 = true; }
        var base = toBase64 ? this.base64ToArrayBuffer(data) : data;
        var blob = new Blob([base], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, fileName);
    };
    FileService.prototype.defaultSave = function (blob, fileName) {
        FileSaver.saveAs(blob, fileName);
    };
    FileService.prototype.base64ToArrayBuffer = function (base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };
    return FileService;
}());
export { FileService };
