var MobileService = /** @class */ (function () {
    function MobileService() {
    }
    MobileService.prototype.isMobile = function () {
        return window.innerWidth < 768;
    };
    MobileService.prototype.isTablet = function () {
        return window.innerWidth >= 768 && window.innerWidth < 992;
    };
    MobileService.prototype.isDevice = function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    return MobileService;
}());
export { MobileService };
