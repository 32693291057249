import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'bracketsPostfix'})
export class BracketsPostfixPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            return '(' + value + ')' + '/';
        }
    }
}
