import { MobileService } from '../../shared/services/mobile.service';
var ScrollingService = /** @class */ (function () {
    function ScrollingService(mobileService) {
        this.mobileService = mobileService;
    }
    ScrollingService.prototype.scrollUp = function (classSelector, timeout, additionalTop, onlyMobile) {
        if (additionalTop === void 0) { additionalTop = 0; }
        if (onlyMobile === void 0) { onlyMobile = false; }
        if (onlyMobile && !this.mobileService.isDevice()) {
            return;
        }
        setTimeout(function () {
            var element = document.getElementsByClassName(classSelector)[0];
            if (element) {
                var newOffset = window.pageYOffset + element.getBoundingClientRect().top - additionalTop;
                if (newOffset < window.pageYOffset) {
                    $('html, body').animate({ scrollTop: newOffset }, 700);
                }
            }
        }, timeout);
    };
    return ScrollingService;
}());
export { ScrollingService };
