import { Routes } from '@angular/router';
import { AuthComponent } from 'app/auth/azure-auth/auth.component';
import { UmbracoConfigGuard } from 'app/auth/umbraco-config-guard';
import { MaintanceErrorComponent } from 'app/containers/error-page/maintance-error/maintance-error';
var appRoutes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: 'app', loadChildren: './containers/layout/layout.module#LayoutModule', canActivate: [UmbracoConfigGuard] },
    { path: 'auth', component: AuthComponent, pathMatch: 'full' },
    { path: 'maintance-error', component: MaintanceErrorComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
