import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../../../config/config.sevice';
import { parse } from 'libphonenumber-js'
declare var require: any;

@Injectable()
export class MobileNumberService {
    blockedCountries: any[];
    constructor(private configService: ConfigService) {
        this.blockedCountries = this.configService.get('blockedCountries');
    }

    getCountryPhoneCodes(): Observable<any> {
        return new Observable<any>(observer => {
            const countryPhoneCodesData = require('assets/data/country-phone-codes.json');
            const mapCountryPhoneCodes = countryPhoneCodesData.countries
                .map(country => ({ id: country.dial_code, text: country.dial_code }))
                .sort((first, second) => first.id - second.id)
                .filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === item.id && t.text === item.text
                    ))
                );

            observer.next(mapCountryPhoneCodes);
            observer.complete();
        });
    }

    isCountryBlocked(phoneNumber: string) {
        if (!phoneNumber) {
            return false;
        }
        const parsedCountry = parse(phoneNumber);
        return this.blockedCountries && this.blockedCountries.some(country => country.code === parsedCountry.country);
    }
}
