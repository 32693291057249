import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { AuthService } from 'app/auth/auth.service';
import { CookieService } from 'ngx-cookie';
import { JwtHelper } from 'app/shared/helpers/JWTParser';
import { MessageModalService } from 'app/shared/components/message-modal/message-modal.service';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
import { ConfigService } from 'app/config/config.sevice';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
var AuthComponent = /** @class */ (function () {
    function AuthComponent(authService, route, cookieService, routerWrapper, messageModal, configService, translate, environmentConfig, errorHandlerServise) {
        var _this = this;
        this.authService = authService;
        this.route = route;
        this.cookieService = cookieService;
        this.routerWrapper = routerWrapper;
        this.messageModal = messageModal;
        this.configService = configService;
        this.translate = translate;
        this.environmentConfig = environmentConfig;
        this.errorHandlerServise = errorHandlerServise;
        this.authLayer = {
            setAuthToken: function (tokenDataId) {
                _this.cookieService.put('zim_access_token', tokenDataId, { secure: true });
                forkJoin(_this.authService.getAuthData(), _this.configService.loadConfig()).subscribe(function (_a) {
                    var data = _a[0];
                    _this.languages = _this.configService.get('languages');
                    if (data.contact == null || data.dsaData == null) {
                        _this.routerWrapper.toLogin();
                        return;
                    }
                    _this.authService.setAuthData(data);
                    _this.setLanguage(data.contact.language);
                    var redirectUrl = _this.cookieService.get('zim_redirect_url');
                    if (redirectUrl) {
                        _this.cookieService.remove('zim_redirect_url');
                        _this.routerWrapper.toPage(redirectUrl);
                    }
                    else {
                        _this.routerWrapper.toHome();
                    }
                }, function (error) {
                    if (error.status === 401) {
                        _this.routerWrapper.toLogin();
                        return;
                    }
                    _this.routerWrapper.toErrorPage();
                });
            },
            checkErrors: function () {
                if (window.location.href.includes('error_description=AADB2C90118')) {
                    window.location.href = _this.environmentConfig.get('signIn').forgotPasswordLink;
                    return;
                }
                else if (window.location.href.includes('error_description=AADB2C90091')) {
                    window.location.href = _this.environmentConfig.get('signIn').loginLink;
                    return;
                }
                else if (window.location.href.includes('error_description=AADB2C90075')) {
                    _this.routerWrapper.toErrorPage();
                    return;
                }
            }
        };
        this.jwtHelper = new JwtHelper();
        this.languages = this.configService.get('languages');
    }
    AuthComponent.prototype.ngOnInit = function () {
        var token = this.getParameterByName('#id_token', window.location.href);
        var error = this.getParameterByName('#error', window.location.href);
        if (!token) {
            window.location.href = this.environmentConfig.get('signIn').loginLink;
        }
        this.authLayer.checkErrors();
        if (error) {
            return;
        }
        var validDsa = this.authService.checkTokenDsa(token);
        if (validDsa) {
            this.authLayer.setAuthToken(token);
        }
        else {
            this.errorHandlerServise.handleInactiveUserError();
        }
    };
    AuthComponent.prototype.getParameterByName = function (name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[a-z]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };
    AuthComponent.prototype.setLanguage = function (langIso) {
        if (this.languages) {
            if (this.languages.filter(function (x) { return x.iso === langIso; }).length !== 1) {
                var defLang = this.languages.filter(function (x) { return x.isDefault === true; });
                langIso = defLang.length !== 1 ? 'en' : defLang[0].iso;
            }
            this.cookieService.put('language', langIso);
        }
    };
    return AuthComponent;
}());
export { AuthComponent };
