var PermissionsMap = /** @class */ (function () {
    function PermissionsMap() {
        this.customerRoutePermissionMap = {
            'print': 'PRINT_BL',
            'zim-monitor': 'ZIM_MONITOR',
            'imp-my-shipments': 'MYSPMP',
            'exp-my-shipments': 'MYSPXP',
            'arrival-notice': 'ARRNOT',
            'delivery-order': 'DELORD',
            'booking-confirmation': 'BKCONF',
            'bl-copy': 'COPY_BL',
            'seaway-bill': 'SEAWAY',
            'finance': 'FRT_INVOICE_COPY',
            'dashboard': 'DASHBOARD',
            'user-management': 'Admin',
            'schedules-search': 'BKCONF',
            'request-booking': 'BKCONF',
            'notification-settings': 'PUSHNTF',
            'notifications': 'PUSHNTF'
        };
        this.customerRouteUrlPermissionMap = {
            'PRINT_BL': 'print',
            'ZIM_MONITOR': 'zim-monitor',
            'MYSPMP': 'import/imp-my-shipments',
            'MYSPXP': 'export/exp-my-shipments',
            'ARRNOT': 'import/arrival-notice',
            'DELORD': 'import/delivery-order',
            'BKCONF': 'export/booking-confirmation',
            'COPY_BL': 'export/bl-copy',
            'SEAWAY': 'export/seaway-bill',
            'FRT_INVOICE_COPY': 'finance',
            'DASHBOARD': 'dashboard',
            'Admin': 'user-management',
            'PUSHNTF': 'notifications',
            'PUSHNTFSETTINGS': 'notification-settings'
        };
    }
    return PermissionsMap;
}());
export { PermissionsMap };
