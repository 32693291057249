import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { RoutePermissionService } from 'app/core/permission/route-permissions.service';
var PermissionsGuard = /** @class */ (function () {
    function PermissionsGuard(router, routePermission) {
        this.router = router;
        this.routePermission = routePermission;
    }
    PermissionsGuard.prototype.canActivate = function (route, state) {
        return this.routePermission.allowedForCustomer(route.routeConfig.data.name);
    };
    return PermissionsGuard;
}());
export { PermissionsGuard };
