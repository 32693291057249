import {NgModule} from '@angular/core';
import {DatepickerWrapDirective} from 'app/shared/directives/datepicker-wrap/datepicker-wrap.directive';

@NgModule({
    declarations: [DatepickerWrapDirective],
    exports: [DatepickerWrapDirective]
})

export class DatepickerWrapModule {
}
