import { HttpClientService } from 'app/core/http/http-client.service';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RouterWrapper } from '../../core/routing/router.wrapper';
var LocalizationService = /** @class */ (function () {
    function LocalizationService(http, environmentConfig, routerWrapper) {
        this.http = http;
        this.environmentConfig = environmentConfig;
        this.routerWrapper = routerWrapper;
        this.apiEndPoint = environmentConfig.get('umbraco').translates;
        this.baseApi = this.environmentConfig.get('baseApiUrl');
    }
    LocalizationService.prototype.getTranslation = function (lang) {
        var _this = this;
        return this.http.get(this.baseApi + this.apiEndPoint + '/' + lang).pipe(catchError(function (error) {
            _this.routerWrapper.toErrorPage();
            return of(error);
        }));
    };
    return LocalizationService;
}());
export { LocalizationService };
