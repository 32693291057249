import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { HttpClientService } from '../../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../../config/evironment-config';

@Injectable()

export class GdprPopupService {
    baseApi: any;
    apiEndPoint: any;
    contactsApiEndpoint: any;

    constructor(
        private httpClient: HttpClientService,
        private environmentConfiguration: EnvironmentConfiguration
    ) {
        this.apiEndPoint = environmentConfiguration.get('gdprEndPoint');
        this.baseApi = environmentConfiguration.get('baseApiUrl');
        this.contactsApiEndpoint = this.environmentConfiguration.get('getmycontactEndPoint').contacts;
    }

    getMyContact(): Observable<any> {
        return this.httpClient.get(this.baseApi + this.contactsApiEndpoint);
    }

    getPurpose(categoryname, language, email: string): Observable<any> {
        const query = `?category=${categoryname}&lang=${language}&email=${email}`;
        return this.httpClient.get(this.baseApi + this.apiEndPoint.getPurpose + query);
    }

    setNewGdprConfirmation(request): Observable<any> {
        return this.httpClient.post(this.baseApi + this.apiEndPoint.newGdprConfirmation, request);
    }

    checkConsent(purposeId: string): Observable<any> {
        const request = {
            purposeId: purposeId
        };
        return this.httpClient.post(this.baseApi + this.apiEndPoint.checkConsent, request);
    }
}
