import {NgModule} from '@angular/core';
import {PagingShowDirective} from './paging-show.directive';

@NgModule({
    declarations: [
        PagingShowDirective
    ],
    exports: [
        PagingShowDirective
    ]
})

export class PagingShowModule {
}
