import {NgModule} from '@angular/core';
import {ScrollTableMobileDirective} from './scroll-table-mobile.directive';

@NgModule({
    declarations: [
        ScrollTableMobileDirective
    ],
    exports: [
        ScrollTableMobileDirective
    ]
})

export class ScrollTableMobileModule {
}
