import {Injectable} from '@angular/core';
import {MobileService} from '../../shared/services/mobile.service';

@Injectable()
export class ScrollingService {

    constructor(private mobileService: MobileService) {
    }

    scrollUp(classSelector: string, timeout: number, additionalTop: number = 0, onlyMobile: boolean = false) {
        if (onlyMobile && !this.mobileService.isDevice()) {
            return;
        }

        setTimeout(() => {
            const element = document.getElementsByClassName(classSelector)[0];
            if (element) {
                const newOffset = window.pageYOffset + element.getBoundingClientRect().top - additionalTop;
                if (newOffset < window.pageYOffset) {
                    $('html, body').animate({scrollTop: newOffset}, 700);
                }
            }
        }, timeout);
    }
}
