
import {throwError as observableThrowError} from 'rxjs';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {CookieService} from 'ngx-cookie';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class RequestHelper {

    constructor(
        private cookiesService: CookieService,
        private routerWrapper: RouterWrapper,
    ) {

    }

    public errorHandler(error: any) {
        switch (error.status) {
            case 401:
                this.routerWrapper.toLogin();
        }

        return observableThrowError(error);
    }

    private setHeaders(headers) {
        if (!headers) {
            headers = new HttpHeaders({'Content-Type': 'application/json'});
        } else {
            if (!headers.has('Content-Type')) {
                headers.append('Content-Type', 'application/json');
            }
        }

        return headers;
    }

    private setAuthToken(headers: HttpHeaders) {
        const access_token = this.cookiesService.get('zim_access_token');
        headers = headers.append('Authorization', 'Bearer ' + access_token);
        return headers;
    }

    public initHeaders(headers?: HttpHeaders, anonymous: boolean = false): HttpHeaders {
        headers = this.setHeaders(headers);

        if (!anonymous) {
            headers = this.setAuthToken(headers);
        }

        return headers;
    }
}
