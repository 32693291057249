import { PipeTransform } from '@angular/core';
var FollowOrderPipe = /** @class */ (function () {
    function FollowOrderPipe() {
    }
    FollowOrderPipe.prototype.transform = function (array, followOrderArr, path) {
        var _this = this;
        if (!array || !path) {
            return array;
        }
        var copyFollowOrderArr = [];
        copyFollowOrderArr.push.apply(copyFollowOrderArr, followOrderArr);
        copyFollowOrderArr.reverse().forEach(function (value) {
            var index = array.findIndex(_this.findIndexPredicate.bind({ path: path, value: value }));
            if (index !== -1) {
                var temp = array[index];
                array.splice(index, 1);
                array.unshift(temp);
            }
        });
        return array;
    };
    FollowOrderPipe.prototype.findIndexPredicate = function (element) {
        var pathTree = this['path'].split('.');
        pathTree.forEach(function (property) {
            element = element[property];
        });
        element = element.split(' ');
        var value = this['value'].split(' ');
        return element[0] === value[0];
    };
    return FollowOrderPipe;
}());
export { FollowOrderPipe };
