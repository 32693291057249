<header class="navbar">
    <div class="container">
        <div class="navbar-header">
            <a class="navbar-brand logo" (click)="onDashboardNavigate()"><img src="/assets/images/newZimCALogo.png"
                                                                              [alt]="'Labels.AltNewZimCALogo' | translate" width="120"
                                                                              height="50"></a>
        </div>
        <a class="btn-menu visible-sm visible-xs" role="button" tabindex="0">menu</a>
        <ul class="navbar-right user-block hidden-xs">
            <li class="notifications-info" *ngIf="isNotificationsShowed">
                <a (click)="onNotificationSettingsNavigate()">
                    <img src="/assets/images/bell-notif-white.svg" [alt]="'Labels.AltBellNotifWhite' | translate">
                    <span class="fa fa-circle" aria-hidden="true" *ngIf="showNotificationsExistence"></span>
                </a>
            </li>
            <li *ngIf="showLanguageSelect" class="language-select-wrapper">
                <label for="language-select" class="sr-only">Language selector</label>
                <select2 id="language-select" class="common-select" [data]="languages"
                         [value]="initLanguage" [width]="'100px'"
                         [options]="{theme: 'bootstrap', dropdownCssClass: 'language-select-drop no-search'}"
                         (valueChanged)="languageChanged($event.value)"
                         ngDefaultControl>
                </select2>
            </li>
            <li class="personal-info" *ngIf="showPersonal">
                <div id="personal-settings-btn" (clickOutside)="closePersonalInfo()" (click)="onShowPersonalInfoToggle()" (keyup.enter)="onShowPersonalInfoToggle()" tabindex="0" role="button" aria-haspopup="true" [attr.aria-expanded]="showPersonalInfo">
                    <span class="name" [attr.aria-label]="'Labels.AriaLblPersonalInfoName'| translate">{{fullName}}</span>
                    <span class="info-arrow"><img src="../../../../assets/images/arrow_down_nav.png" height="6" width="13"
                        [alt]="'Labels.AltArrowDownNav' | translate" [class.emit-arrow-up]="showPersonalInfo"/>
                    </span>
                </div>
                <div class="personal-settings-dropdown" *ngIf="showPersonalInfo">
                    <div class="account-info">
                        <p class="account-info-name">
                            {{fullName}}
                        </p>
                        <p class="account-info-email">
                            {{userEmail}}
                        </p>
                    </div>
                    <ul>
                        <li *ngIf="showMySettingsLink" (click)="onMySettingsNavigate()" (keyup.enter)="onMySettingsNavigate()"><button aria-label="Open account settings">{{'Labels.MySettings' | translate}}</button>
                            <img class="mysettings-img" src="/assets/images/ico-23.svg" [alt]="'Labels.AltMySettingsImg' | translate" height="18" width="18"/></li>
                        <li (click)="onLogout()" (keyup.enter)="onLogout()"><button aria-label="Log out">{{'Labels.LogoutOfMyZIM' | translate}}</button>
                            <img class="logout-img" src="/assets/images/signout.png" [alt]="'Labels.SignOut' | translate" height="19" width="19"/></li>
                    </ul>
                </div>
            </li>
        </ul><!-- / user-block -->
        <div class="country-menu" *ngIf="false">
            <div class="holder">
                <div class="head">
                    <h3>Are you visiting from USA?</h3>
                    <a class="btn btn-primary" role="button" tabindex="0">YES</a>
                </div>
                <h4>Select another country</h4>
                <div class="country-table">
                    <table>
                        <tr>
                            <th id="col-1">Africa</th>
                            <th id="col-2" colspan="3">Americas</th>
                            <th id="col-3" colspan="2">Asia &amp; Oceania</th>
                            <th id="col-4" colspan="3">Europe</th>
                        </tr>
                        <tr>
                            <td headers="col-1">
                                <ul>
                                    <li><a role="button" tabindex="0">Benin</a></li>
                                    <li><a role="button" tabindex="0">Egypt</a></li>
                                    <li><a role="button" tabindex="0">Ghana</a></li>
                                    <li><a role="button" tabindex="0">Ivory Coast</a></li>
                                    <li><a role="button" tabindex="0">Morocco</a></li>
                                    <li><a role="button" tabindex="0">Nigeria</a></li>
                                    <li><a role="button" tabindex="0">Senegal</a></li>
                                    <li><a role="button" tabindex="0">Togo</a></li>
                                </ul>
                            </td>
                            <td headers="col-2">
                                <ul>
                                    <li><a role="button" tabindex="0">Antigua and Barbuda</a></li>
                                    <li><a role="button" tabindex="0">Argentina</a></li>
                                    <li><a role="button" tabindex="0">Aruba</a></li>
                                    <li><a role="button" tabindex="0">Bahamas</a></li>
                                    <li><a role="button" tabindex="0">Barbados</a></li>
                                    <li><a role="button" tabindex="0">Belize</a></li>
                                    <li><a role="button" tabindex="0">Bermuda</a></li>
                                    <li><a role="button" tabindex="0">Brazil</a></li>
                                    <li><a role="button" tabindex="0">British Virgin Islands</a></li>
                                    <li><a role="button" tabindex="0">Canada</a></li>
                                    <li><a role="button" tabindex="0">Chile</a></li>
                                    <li><a role="button" tabindex="0">Colombia</a></li>
                                    <li><a role="button" tabindex="0">Costa Rica</a></li>
                                    <li><a role="button" tabindex="0">Curacao</a></li>
                                </ul>
                            </td>
                            <td headers="col-4">
                                <ul>
                                    <li><a role="button" tabindex="0">Slovakia</a></li>
                                    <li><a role="button" tabindex="0">Slovenia</a></li>
                                    <li><a role="button" tabindex="0">Spain</a></li>
                                    <li><a role="button" tabindex="0">Sweden</a></li>
                                    <li><a role="button" tabindex="0">Switzerland</a></li>
                                    <li><a role="button" tabindex="0">Ukraine</a></li>
                                    <li><a role="button" tabindex="0">United Kingdom</a></li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>
                <a class="btn-close" role="button" tabindex="0">Close<span class="ico"></span></a>
            </div>
        </div><!-- / country-menu -->
        <ul class="mobile-tools visible-xs">
            <li class="logout-button-li">
                <button type="button" class="btn btn-sm btn-primary logout-button" (click)="onLogout()">
                    {{'Labels.LogOut' | translate}}
                </button>
            </li>
        </ul>
        <nav class="main-nav" *ngIf="enableTopMenu">
            <ul #listHandler>
                <ng-container *ngFor="let title of subPages.SubPages">
                    <li class="has-mega-menu" *ngIf="title.Name !== 'Contact Us'"><a role="button" tabindex="0"
                                                                                     [listHandler]='listHandler'
                                                                                     navBarOpen>{{title.Name}}</a>
                        <div class="mega-menu">
                            <div class="row">
                                <div>
                                    <div class="inner">
                                        <ul class="list">
                                            <li *ngFor="let subTitle of title.SubPages"><a href="{{subTitle.Url}}">{{subTitle.Name}}</a>
                                                <ul>
                                                    <li *ngFor="let item of subTitle.SubPages"><a href="{{item.Url}}">{{item.Name}}</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <a class="btn-close" role="button" tabindex="0">Close<span class="ico"></span></a>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </nav><!-- / main-nav -->
        <div class="inner">
            <ul class="mobile-nav">
                <li *ngFor="let title of subPages.SubPages"><a role="button" tabindex="0">{{title.Name}}</a>
                    <ul class="drop-menu type2">
                        <li *ngFor="let subTitle of title.SubPages"><a href="{{subTitle.Url}}">{{subTitle.Name}}</a>
                            <ul>
                                <li *ngFor="let item of subTitle.SubPages"><a href="{{item.Url}}">{{item.Name}}</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul><!-- / mobile-nav -->
        </div><!-- / inner -->
    </div><!-- / container -->

</header>
